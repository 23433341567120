import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {
    Alert,
    Button,
    Card,
    Col,
    Descriptions,
    Form,
    Input,
    List,
    message,
    Modal, Popover,
    Row,
    Space,
    Switch, Table,
    Tooltip
} from "antd";
import {
    changeCpanelPassword,
    getSharedHostingPassword,
    loadHostingAccount,
    loginTocPanel,
    updateHostingAccountField
} from "../../actions/hosting";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {Loading} from "../../libs/loading";
import {DateTimeFormat} from "../../components/shared/DateTimeFormat";
import {
    ExclamationCircleOutlined,
    EyeOutlined,
    LockOutlined,
    QuestionCircleOutlined,
    SaveOutlined
} from "@ant-design/icons";
import LoginOutlined from "@ant-design/icons/lib/icons/LoginOutlined";
import HostingAccountDomainsTable from "../../components/hosting/HostingAccountDomainsTable";
import {StatusIndicator} from "../../components/shared/Status";
import Paragraph from "antd/es/typography/Paragraph";
import AccessGate from "../../components/permissions/AccessGate";
import {hasPermission} from "../../components/permissions/PermissionUtils";
import AccessItem from "../../components/permissions/AccessItem";
import {Link} from "react-router-dom";
import {DateFormat} from "../../components/shared/DateFormat";

class HostingDetails extends React.Component {
    state = {
        loadingHostingAccount: true,
        data: null,
        hostingPassword: '',
        accountPassword: '',
        loadingPassword: false,
        showPasswordModal: false,
        passwordInputType: 'password',
        showCpanelPasswordChangeModal: false,
        newRepeatCpanelPassword: '',
        newCpanelPassword: '',
        currentCpanelPassword: '',
        changingCpanelPassword: false,
        name: ''
    };

    componentDidMount() {
        this.loadHostingAccount()
    }

    loadHostingAccount() {
        this.setState({ loadingHostingAccount: true });

        this.props.loadHostingAccount(this.props.match.params.guid, (res) => {
            this.setState({ loadingHostingAccount: false, data: res.data, name: res.data.name });
            this.props.updatePage(res.data.name);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loadingHostingAccount: false });
            }
        });
    }

    getSharedHostingPassword() {
        let password = this.state.accountPassword.trim();

        if(password.length === 0) {
            return message.error('Please enter your account password!');
        }

        let data = { password };

        this.setState({ loadingPassword: true });

        this.props.getSharedHostingPassword(this.props.match.params.guid, data, (res) => {
            this.setState({ loadingPassword: false, hostingPassword: res.data.password, showPasswordModal: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loadingPassword: false });
            }
        });
    }

    loginToCpanel() {
        message.info('Creating cPanel login URL...');

        this.props.loginTocPanel(this.props.match.params.guid, (res) => {
            window.open(res.data.redirect_url);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    changeCpanelPassword() {
        let current_password = this.state.currentCpanelPassword;
        let new_password = this.state.newCpanelPassword;
        let new_password_repeat = this.state.newRepeatCpanelPassword;

        if(new_password !== new_password_repeat) {
            return message.error('Your new passwords do not match!');
        }

        let data = { current_password, new_password, new_password_repeat };

        this.setState({ changingCpanelPassword: true });

        this.props.changeCpanelPassword(this.props.match.params.guid, data, () => {
            this.setState({ changingCpanelPassword: false, showCpanelPasswordChangeModal: false,
                currentCpanelPassword: '', newCpanelPassword: '', newRepeatCpanelPassword: '' });
            message.success('cPanel password successfully updated!');

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ changingCpanelPassword: false });
            }
        });
    }

    updateHostingAccountName() {
        let name = this.state.name.trim();

        if(name.length === 0) {
            return message.error('Please enter your account name!');
        }

        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to set the name to <strong>"{this.state.name}"</strong>?</span>,
            onOk: () => {
                this.setState({ loading: true });
                let data = { name };

                this.props.updateHostingAccountField(this.props.match.params.guid, data, () => {
                    message.success('Hosting account\'s name successfully updated!');
                    this.loadHostingAccount();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ loading: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    updateDomainDeletion(value) {
        let name = this.state.name.trim();

        if(name.length === 0) {
            return message.error('Please enter your account name!');
        }

        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <div>
                <p>Are you sure you want to <strong>{value ? 'enable' : 'disable'}</strong> domain deleting for this hosting account?</p>
                <p>If domain deletion is enabled then <strong>your domain will be automatically deleted from cPanel</strong> domains when you delete your domain from our panel.</p>
            </div>,
            onOk: () => {
                this.setState({ loading: true });
                let data = { allow_domain_deletion: value };

                this.props.updateHostingAccountField(this.props.match.params.guid, data, () => {
                    message.success('Domain deletion successfully ' + (value ? 'enabled' : 'disabled') + '!');
                    this.loadHostingAccount();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ loading: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    render() {
        if(this.state.loadingHostingAccount) {
            return <div className='text-center'><Loading /></div>;
        }

        const { data } = this.state;

        if(data === null) {
            return <Alert message='Something went wrong!' type='error' />;
        }

        let sharedHostingPlanDetails = '';

        if(data.account_type === 'SHARED_HOSTING') {
            sharedHostingPlanDetails = <Card size='small' title='Hosting Plan' className='card-bottom-margin'>
                <div style={{overflowX: 'auto'}}>
                    <Table
                        dataSource={[data.plan]}
                        rowKey={(item) => item.name}
                        pagination={false} size='small' columns={[
                        {title: 'Plan', dataIndex: 'name', width: 150, fixed: 'left'},
                        {title: 'Storage', dataIndex: 'storage'},
                        {title: 'CPU', dataIndex: 'cpu'},
                        {title: 'RAM', dataIndex: 'memory'},
                        {title: 'Inodes', dataIndex: 'inodes'},
                        {title: 'Bandwidth', dataIndex: 'bandwidth'},
                        {title: <Tooltip title='Entry processes'>Entry Proc.</Tooltip>, dataIndex: 'entry_processes'},
                        {
                            title: <Tooltip title='Active processes'>Active Proc.</Tooltip>,
                            dataIndex: 'active_processes'
                        },
                        {title: <Tooltip title='Email accounts'>Email acc.</Tooltip>, dataIndex: 'email_accounts'},
                        {title: 'Databases', dataIndex: 'databases'},
                        {title: 'Subdomains', dataIndex: 'subdomains'},
                        {title: 'Parked Domains', dataIndex: 'parked_domains'},
                        {title: 'Addon Domains', dataIndex: 'addon_domains'},
                    ]}/>
                </div>
            </Card>
        ;
        }

        let serverDetails;

        if(data.account_type === 'CUSTOM_HOST') {
            serverDetails = <Card size='small' title='Server Details' className='card-bottom-margin'>
                <div style={{overflowX: 'auto'}}>
                    <Descriptions size='small' column={1} bordered>
                        <Descriptions.Item label='Destination type' style={{width: '50%'}}>{normalizeEnum(data.custom_host_server.destination_type)}</Descriptions.Item>
                        {data.custom_host_server.destination_type === 'HOSTNAME' ? <Descriptions.Item label='Hostname' style={{width: '50%'}}>{data.custom_host_server.hostname}</Descriptions.Item> : ''}
                        {data.custom_host_server.destination_type === 'IP_ADDRESS' ? <Descriptions.Item label='IP address'>{data.custom_host_server.ip_address}</Descriptions.Item> : ''}
                    </Descriptions>
                </div>
            </Card>;
        } else {
            serverDetails = <Card size='small' title='Service Details' className='card-bottom-margin'>
                <div style={{overflowX: 'auto'}}>
                    <Descriptions size='small' column={1} bordered>
                        <Descriptions.Item label='Hostname'
                                           style={{width: '50%'}}>{data.hosting_server.hostname}</Descriptions.Item>
                        {data.is_legacy ? '' : <Descriptions.Item label='Nameservers'>
                            <List
                                dataSource={['dns1.sitehostingserver.com (144.126.250.10)', 'dns2.sitehostingserver.com (165.227.254.107)']}
                                renderItem={item => (
                                    <List.Item>{item}</List.Item>
                                )}/>
                        </Descriptions.Item>}
                        <Descriptions.Item label='IP address'>{data.hosting_server.ip_address}</Descriptions.Item>
                        <Descriptions.Item
                            label='Location'>{data.location.city + ', ' + data.location.country_name}</Descriptions.Item>
                        <Descriptions.Item label='Username'><Paragraph copyable
                                                                       style={{margin: 0}}>{data.username}</Paragraph></Descriptions.Item>
                        <Descriptions.Item label='Password'>
                            {this.state.hostingPassword === '' ?
                                <Button onClick={() => this.setState({showPasswordModal: true})} size='small'
                                        icon={<EyeOutlined/>}>View cPanel Password</Button> :
                                <Paragraph copyable style={{margin: 0}}>{this.state.hostingPassword}</Paragraph>}
                        </Descriptions.Item>
                        <Descriptions.Item label='Actions'>
                            <Space>
                                <AccessItem scopes={['can_login_to_hosting_accounts']}>
                                    <Button size='small' disabled={this.state.changingCpanelPassword}
                                            icon={<LoginOutlined/>} onClick={() => this.loginToCpanel()}>Login to
                                        cPanel</Button>
                                </AccessItem>
                                <AccessItem scopes={['can_edit_hosting_accounts']}>
                                    <Button size='small' disabled={this.state.changingCpanelPassword}
                                            loading={this.state.changingCpanelPassword} icon={<LockOutlined/>}
                                            onClick={() => this.setState({showCpanelPasswordChangeModal: true})}>Change
                                        cPanel Password</Button>
                                </AccessItem>
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label={<span>Allow domain deletion <Tooltip
                            title='When enabled, your domain will be deleted from your hosting account when you delete your domain from the panel!'><QuestionCircleOutlined/></Tooltip></span>}>
                            <AccessItem scopes={['can_edit_hosting_accounts']}>
                                <Switch size="small" disabled={data.is_legacy} checked={data.allow_domain_deletion}
                                        onChange={(value) => this.updateDomainDeletion(value)}/>
                            </AccessItem>
                            {!hasPermission(this.props.company.permissions, ['can_edit_hosting_accounts']) ?
                                <StatusIndicator status={data.allow_domain_deletion} el='badge'/> : ''}
                        </Descriptions.Item>
                    </Descriptions>
                </div>

                    <Modal
                        onCancel={() => this.setState({showCpanelPasswordChangeModal: false})}
                        title='Change Password'
                        footer={<Space>
                            <Button disabled={this.state.changingCpanelPassword}
                                    onClick={() => this.setState({showCpanelPasswordChangeModal: false})}>Close</Button>
                            <Button disabled={this.state.changingCpanelPassword}
                                    onKeyDown={(e) => e.key === 'Enter' ? this.changeCpanelPassword() : null}
                                    loading={this.state.changingCpanelPassword} type='primary'
                                    onClick={() => this.changeCpanelPassword()}>Update</Button>
                        </Space>}
                        open={this.state.showCpanelPasswordChangeModal}>
                        <Form layout='vertical'>
                            <Form.Item label='Current password:'>
                                <Input type='password' value={this.state.currentCpanelPassword}
                                       onChange={(e) => this.setState({currentCpanelPassword: e.target.value})}/>
                            </Form.Item>
                            <Form.Item label='New password:'>
                                <Input type='password' value={this.state.newCpanelPassword}
                                       onChange={(e) => this.setState({newCpanelPassword: e.target.value})}/>
                            </Form.Item>
                            <Form.Item label='Repeat password:'>
                                <Input type='password' value={this.state.newRepeatCpanelPassword}
                                       onChange={(e) => this.setState({newRepeatCpanelPassword: e.target.value})}/>
                            </Form.Item>
                        </Form>
                    </Modal>
            </Card>
        ;
        }

        return(
            <AccessGate scopes={['can_view_hosting_accounts']}>
                {data.status === 'SUSPENDED' ? <>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Alert showIcon message={<span>This hosting account is suspended and scheduled for termination on <b><DateFormat value={data.date_to_be_terminated} /></b>. Please renew your hosting account to prevent permanent data loss!</span>} type='error' />
                        </Col>
                    </Row>
                    <br />
                </> : ''}
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12}>
                        <Card size='small' title='Service Information' className='card-bottom-margin'>
                            <div style={{overflowX: 'auto'}}>
                                <Descriptions size='small' column={1} bordered>
                                    <Descriptions.Item label='Name' style={{width: '50%'}}>
                                        <AccessItem scopes={['can_edit_hosting_accounts']}>
                                            <Row>
                                                <Col flex="auto"><Input value={this.state.name}
                                                                        onChange={(e) => this.setState({name: e.target.value})}/></Col>
                                                <Col><Button style={{marginLeft: '5px'}} icon={<SaveOutlined/>}
                                                             onClick={() => this.updateHostingAccountName()}/></Col>
                                            </Row>
                                        </AccessItem>
                                        {!hasPermission(this.props.company.permissions, ['can_edit_hosting_accounts']) ? this.state.name : ''}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label='Type'>{normalizeEnum(data.account_type)}</Descriptions.Item>
                                    <Descriptions.Item label='Created'><DateTimeFormat
                                        value={data.date_created}/></Descriptions.Item>
                                    <Descriptions.Item label='Status'><StatusIndicator status={data.status}
                                                                                       el={'badge'}/></Descriptions.Item>
                                    {typeof data.order !== 'undefined' ? <Descriptions.Item label='Order'><Link
                                        to={'/billing/orders/' + data.order}>#{data.order}</Link></Descriptions.Item> : ''}
                                    {typeof data.order !== 'undefined' ?
                                        <Descriptions.Item label='Service renewal'><DateFormat
                                            value={data.order_renewal}/></Descriptions.Item> : ''}
                                    {typeof data.order !== 'undefined' && data.status === 'SUSPENDED' ?
                                        <Descriptions.Item label='Termination date'>
                                            <Space>
                                                <DateFormat value={data.date_to_be_terminated}/>
                                                <Popover
                                                    content={<Fragment>The termination date is the date when the hosting
                                                        account<br/>and backups are permanently deleted.</Fragment>}
                                                    title="Termination date">
                                                    <QuestionCircleOutlined/>
                                                </Popover>
                                            </Space>
                                        </Descriptions.Item> : ''}
                                </Descriptions>
                            </div>
                        </Card>
                    </Col>
                    <Col span={24} sm={12}>
                        {serverDetails}
                    </Col>
                </Row>
                {data.account_type === 'SHARED_HOSTING' ? <Fragment>
                    <br/>
                    <Row>
                        <Col span={24}>
                            {sharedHostingPlanDetails}
                        </Col>
                    </Row>
                </Fragment> : ''}
                <AccessItem scopes={['can_view_domains']}>
                    <br/>
                    <Card size='small'>
                        <HostingAccountDomainsTable guid={this.props.match.params.guid} />
                    </Card>
                </AccessItem>

                <Modal
                    open={this.state.showPasswordModal}
                    footer={<Space>
                        <Button onClick={() => this.setState({ showPasswordModal: false, accountPassword: '' })}>Close</Button>
                        <Button onClick={() => this.getSharedHostingPassword()} type='primary'>Continue</Button>
                    </Space>}
                    onOk={() => this.getSharedHostingPassword()}
                    onCancel={() => this.setState({ showPasswordModal: false, accountPassword: '' })}>
                    <p>Enter your account password:</p>

                    <Input type='password'
                           onKeyDown={(e) => e.key === 'Enter' ? this.getSharedHostingPassword() : null}
                           onChange={(e) => this.setState({ accountPassword: e.target.value})}
                           value={this.state.accountPassword} />
                </Modal>
            </AccessGate>
        );
    }
}

const mapStateToProps = state => ({
    company: state.auth.user.user.company
});

export default connect(mapStateToProps, { updatePage, loadHostingAccount, getSharedHostingPassword,
    loginTocPanel, changeCpanelPassword, updateHostingAccountField })(HostingDetails);