import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Alert, Button, Col, Modal, Result, Row, Space, Steps, Tabs, Typography} from "antd";
import Confetti from "react-confetti-boom";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {
    closeGettingStartedWizard, minimizeGettingStartedWizard,
    startGettingStartedWizard,
    stopGettingStartedWizard,
    updateGettingStartedWizardStep
} from "../../actions/getting_started_wizard";
import {updateDriverHighlight} from "../../actions/driver";
import {MdSupport} from "react-icons/md";
import {HiArrowLongRight} from "react-icons/hi2";


class GettingStartedWizard extends React.Component {
    state = {
        activeIntroTab: 'intro',
        wizardStepItems: [
            { title: 'Introduction' },
            { title: 'Payment method' },
            { title: 'Hosting account' },
            { title: 'IP address group' },
            { title: 'Your first IP addresses' },
            { title: 'Domain group' },
            { title: 'First domain' },
        ]
    }

    closeModal() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes, close',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <div>
                <p>Are you sure you wish to stop the getting started wizard?</p>
                <p>You can open the getting started wizard again by reloading the page.</p>
            </div>,
            onOk: () => {
                this.props.closeGettingStartedWizard();
            }
        });
    }

    startWizard() {
        this.props.updateGettingStartedWizardStep(this.props.user.user.company.guid, 2);
    }

    restartWizard() {
        this.props.updateGettingStartedWizardStep(this.props.user.user.company.guid, 2);
    }

    nextStep() {
        this.props.updateGettingStartedWizardStep(this.props.user.user.company.guid, this.props.wizard.currentStep+1);
    }

    previousStep() {
        this.props.updateGettingStartedWizardStep(this.props.user.user.company.guid, this.props.wizard.currentStep-1);
    }

    addPaymentMethod() {
        this.props.history.push('/billing/payment-methods');
        this.minimizeWizard();

        setTimeout(() => {
            this.props.updateDriverHighlight({
                element: '.add-card-btn',
                popover: {
                    description: 'Click here to add a new payment method.',
                }
            })
        }, 500);
    }

    addHosting() {
        this.props.history.push('/hosting');
        this.minimizeWizard();

        setTimeout(() => {
            this.props.updateDriverHighlight({
                element: '.add-hosting-account-btn',
                popover: {
                    title: 'Hosting Account',
                    description: 'Click here to add a new hosting account.',
                }
            })
        }, 500);
    }

    addDomainGroup() {
        this.props.history.push('/domains');
        this.minimizeWizard();

        setTimeout(() => {
            this.props.updateDriverHighlight({
                element: '.add-domain-group-btn',
                popover: {
                    title: 'Domain Group',
                    description: 'Click here to add a new domain group. A domain group contains your domains and helps you organize your domains in a logical way.',
                }
            })
        }, 500);
    }

    addIPGroup() {
        this.props.history.push('/ip-addresses');
        this.minimizeWizard();

        setTimeout(() => {
            this.props.updateDriverHighlight({
                element: '.add-ip-group-btn',
                popover: {
                    title: 'IP Address Group',
                    description: 'Click here to add a new IP address group. An IP address group contains your IP addresses and lets you organize your IP addresses in a logical way.',
                }
            })
        }, 500);
    }

    addIPAddresses() {
        this.props.history.push('/ip-addresses/' + this.props.wizard.wizardData.ip_group);

        this.minimizeWizard();

        setTimeout(() => {
            this.props.updateDriverHighlight({
                element: '.add-ip-addresses-btn',
                popover: {
                    title: 'Add IP Addresses',
                    description: 'Click here to add new IP addresses to your IP address group. Our IP address adding wizard will guide you through the process of adding IP addresses.',
                }
            })
        }, 500);
    }

    addDomains() {
        this.props.history.push('/domains/' + this.props.wizard.wizardData.domain_group);

        this.minimizeWizard();

        setTimeout(() => {
            this.props.updateDriverHighlight({
                element: 'div[data-node-key="add_domains"]',
                popover: {
                    title: 'Add Domains',
                    description: 'Click here to add domains.',
                }
            })
        }, 500);
    }

    disableWizard() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes, close',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to stop the getting started wizard?</span>,
            onOk: () => {
                this.props.stopGettingStartedWizard(this.props.user.user.company.guid);
                this.props.closeGettingStartedWizard();
            }
        });
    }

    minimizeWizard() {
        this.props.minimizeGettingStartedWizard();
    }

    openSupport() {
        window.$crisp.push(["do", "chat:show"]);
        window.$crisp.push(["do", "chat:open"]);
        window.$crisp.push(["do", "message:send", ["text", "Hi! I am stuck at \"" + this.state.wizardStepItems[this.props.wizard.currentStep-2].title + "\" step, can you help me?"]]);
    }

    render() {
        if(!this.props.wizard.showWizard) {
            return <></>;
        }

        const { Title } = Typography;

        let wizardSteps = null;
        let footerButtons = null;
        let steps = null;

        if(this.props.wizard.currentStep > 1 && this.props.wizard.currentStep < 9) {
            steps = <Steps
                size='small'
                current={this.props.wizard.currentStep-2}
                direction='vertical'
                items={this.state.wizardStepItems}
            />;
        }

        if(this.props.wizard.currentStep ===  2) {

            const tabs = [
                {
                    label: '', key: 'intro', children: <div>
                        <Title level={4}>Welcome, {this.props.user.user.first_name}!</Title>
                        <p>This setup wizard will guide you through the initial steps to get started with our service.
                            Here’s
                            what you’ll be setting up:</p>
                        <ul>
                            <li>Payment method</li>
                            <li>Hosting account</li>
                            <li>IP address group</li>
                            <li>Your first IP address(es)</li>
                            <li>Domain group</li>
                            <li>Your first domain(s)</li>
                        </ul>
                        <p><b>Minimizing Wizard</b></p>
                        <p>You can minimize the wizard at any time by clicking the "Minimize" button below. It will
                            minimize the
                            wizard to bottom left corner. You can click on the minimized button to open the wizard
                            again.</p>
                        <p><b>Stopping Wizard</b></p>
                        <p>You can exit the wizard at any time by clicking the "Stop Wizard" button below.</p>
                    </div>
                },
                {
                    label: '', key: 'support', children: <div>
                        <Title level={4}>Getting Support</Title>
                        <p>If you should ever need any help, hit the big red button at the top of the wizard.</p>
                    </div>
                }
            ];

            if(this.state.activeIntroTab === 'intro') {
                footerButtons = <Row gutter={[16, 16]} justify='space-between'>
                    <Col><Button onClick={() => this.disableWizard()}>Stop Wizard</Button></Col>
                    <Col>
                        <Button type='primary' onClick={() => this.setState({ activeIntroTab: 'support' })}>Next</Button>
                    </Col>
                </Row>;
            } else if(this.state.activeIntroTab === 'support') {
                footerButtons = <Row gutter={[16, 16]} justify='space-between'>
                    <Col><Button onClick={() => this.disableWizard()}>Stop Wizard</Button></Col>
                    <Col>
                        <Button type='primary' onClick={() => {
                            this.setState({ activeIntroTab: 'intro' });
                            this.nextStep();
                        }}>Next</Button>
                    </Col>
                </Row>;
            }

            wizardSteps = <Tabs activeKey={this.state.activeIntroTab} renderTabBar={() => ''} items={tabs}/>
        }

        if(this.props.wizard.currentStep ===  3) {
            footerButtons = <Row gutter={[16, 16]} justify='space-between'>
                <Col>
                    <Space>
                        <Button danger onClick={() => this.disableWizard()}>Stop Wizard</Button>
                        <Button onClick={() => this.restartWizard()}>Restart Wizard</Button>
                    </Space>
                </Col>
                <Col>
                    <Space>
                        <Button onClick={() => this.minimizeWizard()}>Minimize</Button>
                        <Button onClick={() => this.previousStep()}>Previous</Button>
                        <Button onClick={() => this.nextStep()}>Skip</Button>
                    </Space>
                </Col>
            </Row>;

            wizardSteps = <div>
                <Title level={4}>Payment Method</Title>
                <p>To get started, please set up your payment method. Adding a credit or debit
                    card to your account enables seamless, automatic payment of invoices, eliminating the need for manual processing.</p>
                <p>
                    <Button type='primary' onClick={() => this.addPaymentMethod()}>Add Credit/Debit Card</Button>
                </p>
                <Alert type='info' showIcon message='You can skip this step if you wish to pay with PayPal or bank transfer.' />
            </div>;
        }

        if(this.props.wizard.currentStep ===  4) {
            footerButtons = <Row gutter={[16, 16]} justify='space-between'>
                <Col>
                    <Space>
                        <Button danger onClick={() => this.disableWizard()}>Stop Wizard</Button>
                        <Button onClick={() => this.restartWizard()}>Restart Wizard</Button>
                    </Space>
                </Col>
                <Col>
                    <Space>
                        <Button onClick={() => this.minimizeWizard()}>Minimize</Button>
                        <Button onClick={() => this.previousStep()}>Previous</Button>
                        <Button onClick={() => this.nextStep()}>Skip</Button>
                    </Space>
                </Col>
            </Row>;

            wizardSteps = <div>
                <Title level={4}>Hosting Account</Title>
                <p>Next, it's time to set up a hosting account to manage your website’s assets.</p>
                <p>You can either use your existing hosting account with another provider or, if you don’t have one, select our shared hosting option.
                    Our shared hosting integrates smoothly with our cloud IP address service for easy management.</p>
                <p>Additionally, our hosting allows for automatic software setup, including themes and plugins, and provides convenient one-click login access.</p>

                <p><Alert type='info' showIcon message='The hosting account is required for creating a domain group.'/></p>

                <p>
                    <Button type='primary' onClick={() => this.addHosting()}>Add Hosting Account</Button>
                </p>
            </div>;
        }

        if(this.props.wizard.currentStep ===  5) {
            footerButtons = <Row gutter={[16, 16]} justify='space-between'>
                <Col>
                    <Space>
                        <Button danger onClick={() => this.disableWizard()}>Stop Wizard</Button>
                        <Button onClick={() => this.restartWizard()}>Restart Wizard</Button>
                    </Space>
                </Col>
                <Col>
                    <Space>
                        <Button onClick={() => this.minimizeWizard()}>Minimize</Button>
                        <Button onClick={() => this.previousStep()}>Previous</Button>
                        <Button onClick={() => this.nextStep()}>Skip</Button>
                    </Space>
                </Col>
            </Row>;

            wizardSteps = <div>
                <Title level={4}>IP Address Group</Title>
                <p>Now, let’s set up IP addresses for your upcoming domains. IP address groups help you organize your IPs
                    in a logical way. For instance, you can group IPs by project or by location if you’re creating location-specific websites.</p>
                <p>
                    <Button type='primary' onClick={() => this.addIPGroup()}>Add IP Address Group</Button>
                </p>
            </div>;
        }

        if(this.props.wizard.currentStep ===  6) {
            footerButtons = <Row gutter={[16, 16]} justify='space-between'>
                <Col>
                    <Space>
                        <Button danger onClick={() => this.disableWizard()}>Stop Wizard</Button>
                        <Button onClick={() => this.restartWizard()}>Restart Wizard</Button>
                    </Space>
                </Col>
                <Col>
                    <Space>
                        <Button onClick={() => this.minimizeWizard()}>Minimize</Button>
                        <Button onClick={() => this.previousStep()}>Previous</Button>
                        <Button onClick={() => this.nextStep()}>Skip</Button>
                    </Space>
                </Col>
            </Row>;

            wizardSteps = <div>
                <Title level={4}>First IP Addresses</Title>
                <p>Each domain in our system requires an IP address, and our flexible setup allows you to select precisely what you need.</p>
                <p>Whether you're looking for shared or dedicated IPs, or IP addresses from specific A, B, or C classes, our system has you covered.
                    You can also choose IPs from different countries and cities to target specific locations. With no predefined packages, you’re
                    free to order exactly the number of IP addresses that suit your requirements.</p>

                {this.props.wizard.wizardData !== null && typeof(this.props.wizard.wizardData.ip_group) !== 'undefined' && this.props.wizard.wizardData.ip_group !== null ?
                    <p>
                        <Button type='primary' onClick={() => this.addIPAddresses()}>Add IP Addresses</Button>
                    </p> : <div>
                    <Alert type='warning' message='Before you can add IP addresses, please create an IP address group first!' showIcon /><br />
                        <Button type='primary' onClick={() => {
                            this.props.updateGettingStartedWizardStep(this.props.user.user.company.guid, 5);
                            this.addIPGroup();
                        }}>Add IP Address Group</Button>
                    </div>}


            </div>;
        }

        if (this.props.wizard.currentStep === 7) {
            footerButtons = <Row gutter={[16, 16]} justify='space-between'>
                <Col>
                    <Space>
                        <Button danger onClick={() => this.disableWizard()}>Stop Wizard</Button>
                        <Button onClick={() => this.restartWizard()}>Restart Wizard</Button>
                    </Space>
                </Col>
                <Col>
                    <Space>
                        <Button onClick={() => this.minimizeWizard()}>Minimize</Button>
                        <Button onClick={() => this.previousStep()}>Previous</Button>
                        <Button onClick={() => this.nextStep()}>Skip</Button>
                    </Space>
                </Col>
            </Row>;

            wizardSteps = <div>
                <Title level={4}>Domain Group</Title>
                <p>Domain groups help you organize your domains in a logical way. It's recommended to have one domain group per project for convenience.</p>
                <p>
                    <Button type='primary' onClick={() => this.addDomainGroup()}>Add Domain Group</Button>
                </p>
            </div>;
        }

        if(this.props.wizard.currentStep ===  8) {
            footerButtons = <Row gutter={[16, 16]} justify='space-between'>
                <Col>
                    <Space>
                        <Button danger onClick={() => this.disableWizard()}>Stop Wizard</Button>
                        <Button onClick={() => this.restartWizard()}>Restart Wizard</Button>
                    </Space>
                </Col>
                <Col>
                    <Space>
                        <Button onClick={() => this.minimizeWizard()}>Minimize</Button>
                        <Button onClick={() => this.previousStep()}>Previous</Button>
                        <Button onClick={() => this.nextStep()}>Skip</Button>
                    </Space>
                </Col>
            </Row>;

            wizardSteps = <div>
                <Title level={4}>First Domain</Title>
                <p>With everything in place, it’s now time to add your domains to our system.</p>
                <p>Our automation features enable you to set up multiple domains simultaneously, saving valuable time.
                    During the setup process, you can also automate tasks such as software installation, SSL certificate configuration, redirects, and more.</p>

                {this.props.wizard.wizardData !== null && typeof(this.props.wizard.wizardData.domain_group) !== 'undefined' && this.props.wizard.wizardData.domain_group !== null ?
                    <p>
                        <Button type='primary' onClick={() => this.addDomains()}>Add Domains</Button>
                    </p> : <div>
                        <Alert type='warning'
                               message='Before you can add domains, please create a domain address group first!'
                               showIcon/><br/>
                        <Button type='primary' onClick={() => {
                            this.props.updateGettingStartedWizardStep(this.props.user.user.company.guid, 7);
                            this.addDomainGroup();
                        }}>Add Domain Group</Button>
                    </div>}
            </div>;
        }

        if (this.props.wizard.currentStep === 9) {
            wizardSteps = <Result
                status="success"
                title="Account set up successfully!"
                subTitle="Congratulations! You have successfully set up your Priority Prospect account and the first domain."
                extra={[
                    <Button type="primary" key="continue" onClick={() => this.disableWizard()}>Continue</Button>
                ]}
            />;
        }

        let wizardTitle = <Row gutter={[16, 16]}><Col>Getting Started Wizard</Col></Row>;

        if(this.props.wizard.currentStep > 1 && this.props.wizard.currentStep < 9) {
            wizardTitle = <Row gutter={[16, 16]} justify='space-between' align='middle'>
                <Col>Getting Started Wizard</Col>
                <Col>
                    <Space>
                        {this.state.activeIntroTab === 'support' ? <HiArrowLongRight className='help-button-arrow' /> : ''}
                        <Button onClick={() => this.openSupport()} icon={<MdSupport style={{marginBottom: '-2px', marginRight: '5px'}} />} type="primary" danger>I Need Help!</Button>
                    </Space>

                </Col>
            </Row>;
        } else if(this.props.wizard.currentStep === 1 || this.props.wizard.currentStep === 9) {
            wizardTitle = '';
        }

        return <Fragment>
            {this.props.wizard.currentStep === 1 ? <Confetti mode="boom" spreadDeg={90} particleCount={150} colors={['#0f2463', '#d30082']} /> : ''}
            <Modal
                open={this.props.wizard.showWizard}
                maskClosable={false}
                closable={false}
                centered={true}
                width={this.props.wizard.currentStep === 1 ? 500 : 900}
                title={wizardTitle}
                footer={footerButtons}
                onCancel={() => this.minimizeWizard()}>

                {this.props.wizard.currentStep === 1  ? <div style={{textAlign: 'center'}}>
                    <h1>Welcome {this.props.user.user.first_name}!</h1>
                    <p>Thank you for signing up. Let's get you started with Priority Prospect!</p>
                    <Space>
                        <Button onClick={() => this.disableWizard()}>Close</Button>
                        <Button type='primary' onClick={() => this.startWizard()}>Continue</Button>
                    </Space>
                </div> : <Row gutter={[16, 16]}>
                    <Col xs={0} md={this.props.wizard.currentStep < 9 ? 6 : 0}>{steps}</Col>
                    <Col xs={24} md={this.props.wizard.currentStep < 9 ? 18 : 24}>{wizardSteps}</Col>
                </Row>}
            </Modal>
        </Fragment>;
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    wizard: state.getting_started_wizard
});


export default connect(mapStateToProps, {
    startGettingStartedWizard, stopGettingStartedWizard,
    updateGettingStartedWizardStep,
    closeGettingStartedWizard, minimizeGettingStartedWizard,
    updateDriverHighlight
})(GettingStartedWizard);