import React from "react";
import {connect} from "react-redux";

const LiveChatWidget = ({user, hide}) => {
    const script = document.createElement('script');
    script.async = true;
    script.src = "https://client.crisp.chat/l.js";

    document.getElementsByTagName("head")[0].appendChild(script);

    if(typeof(window.$crisp) === 'undefined') {
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = "50aca0e8-4714-412d-86f5-e0b993a000c2";
        window.$crisp.push(["safe", true]);

        window.CRISP_READY_TRIGGER = function() {
            if(hide) {
                window.$crisp.push(["do", "chat:hide"])
            }

            window.$crisp.push(["set", "user:email", [user.user.email]]);
            window.$crisp.push(["set", "user:nickname", [user.user.first_name + " " + user.user.last_name]]);

            let hasAccountManager = false;
            let accountManagerEmail = null;

            if(typeof(user.user.company.account_manager) !== 'undefined') {
                hasAccountManager = true;
                accountManagerEmail = user.user.company.account_manager.email;
            }

            window.$crisp.push(["set", "session:data", [[
                ["has-account-manager", hasAccountManager],
                ["account-manager", accountManagerEmail],
            ]]]);
        }
    }

    return <></>;
}

const SupportTicketWidget = () => {
    const script = document.createElement('script');
    script.async = true;
    script.src = "https://support.priorityprospect.com/addons/Channels/HelpWidget/Views/js/supportpal.min.js?v=5.4.0";

    document.getElementsByTagName("head")[0].appendChild(script);

    window.supportpalAsyncInit = function () {
        window.SupportPal.mount({
            "baseURL": "https://support.priorityprospect.com/en/helpwidget",
            "hash": "xiQfZYoMDR",
            "type": "popup",
            "position": "right",
            "colour": "#0f2463",
            "buttonIcon": "lifebuoy",
            "knowledgebase": {
                "enabled": true,
                "typeId": "2"
            },
            "submitTicket": {
                "enabled": true,
                "departmentId": "5",
                "subject": true
            }
        });
    };

    return <></>
}

class LiveChat extends React.Component {
    render() {
        if(this.props.wizard.showWizard) {
            return <LiveChatWidget user={this.props.user} hide={true} />
        }

        if(typeof(this.props.user.user.company.account_manager) !== 'undefined') {
            return <LiveChatWidget user={this.props.user} hide={false} />
        }

        if(this.props.user.user.company.is_partner) {
            return <LiveChatWidget user={this.props.user} hide={false} />
        }

        setTimeout(() => {
            if(!this.props.wizard.showWizard && !this.props.wizard.isMinimized && typeof(this.props.user.user.company.account_manager) === 'undefined' && !this.props.user.user.company.is_partner) {
                SupportTicketWidget();
            }
        }, 5000);

        return <></>;
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    wizard: state.getting_started_wizard,
});

export default connect(mapStateToProps, {})(LiveChat);