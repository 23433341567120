import React from "react";
import {connect} from "react-redux";
import {Loading} from "../../libs/loading";
import {Space, Alert, Button, Card, Descriptions, message, Popconfirm, Typography, Tooltip} from "antd";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {DateFormat} from "../shared/DateFormat";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import LoginOutlined from "@ant-design/icons/lib/icons/LoginOutlined";
import {
    deleteDomain,
    importSoftwareInstallation,
    loadDomainDetail,
    uninstallSoftware,
    uninstallSSLCertificate
} from "../../actions/domains";
import SSLCertificateInstallModal from "./SSLCertificateInstallModal";
import SoftwareInstallModal from "./SoftwareInstallModal";
import IPAddressModifyModal from "./IPAddressModifyModal";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {UserOutlined} from "@ant-design/icons";
import {StatusIndicator} from "../shared/Status";
import SoftwareInfoModal from "./SoftwareInfoModal";
import HostingAccountModifyModal from "./HostingAccountModifyModal";
import AccessItem from "../permissions/AccessItem";
import {hasPermission} from "../permissions/PermissionUtils";
import AccessGate from "../permissions/AccessGate";
import {Link} from "react-router-dom";

class DomainOverview extends React.Component {
    state = {
        loadingOverview: true,
        domain: null,
        showSSLCertificateInstallModal: false,
        updatingSSLCertificate: false,
        showSoftwareInstallModal: false,
        updatingSoftware: false,
        showIPAddressModifyModal: false,
        deletingDomain: false,
        activeSoftwareInstallation: '',
        showHostingAccountModifyModal: false,
        importingSoftware: false,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    componentDidMount() {
        this.loadDomainDetail();
    }

    loadDomainDetail() {
        this.props.loadDomainDetail(this.props.guid, (response) => {
            this.setState({ domain: response.data, loadingOverview: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loadingOverview: false })
            }
        });
    }

    uninstallSSLCertificate() {
        this.setState({ updatingSSLCertificate: true });

        this.props.uninstallSSLCertificate(this.props.guid, () => {
            this.setState({ updatingSSLCertificate: false });

            message.success('SSL certificate update successfully scheduled!');

            this.loadDomainDetail();
            this.props.reloadDomains();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ updatingSSLCertificate: false });
                displayErrors(err.response.data);
            }
        });
    }

    uninstallSoftware() {
        this.setState({ updatingSoftware: true });

        this.props.uninstallSoftware(this.props.guid, () => {
            this.setState({ updatingSoftware: false });

            message.success('Software update successfully scheduled!');

            this.loadDomainDetail();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ updatingSoftware: false });
                displayErrors(err.response.data);
            }
        });
    }

    deleteDomain() {
        this.setState({ deletingDomain: true });

        this.props.deleteDomain(this.props.guid, () => {

            this.props.closeOverview();
            this.props.reloadDomains();

            this.setState({ deletingDomain: false });
            message.success('Domain deletion successfully scheduled!');

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ deletingDomain: false });
                displayErrors(err.response.data);
            }
        });
    }

    importSoftware() {
        this.setState({ importingSoftware: true });

        this.props.importSoftwareInstallation(this.props.guid, () => {
            this.setState({ importingSoftware: false });
            this.props.reloadDomains();
            this.loadDomainDetail();

            message.success('Software successfully imported!');

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ importingSoftware: false });
                displayErrors(err.response.data);
            }
        });
    }

    downloadSoftwareBackup() {
        message.error('Not implemented, yet!');
        // TODO: Add download software backup
    }

    viewSoftwareInfo(guid) {
        this.setState({ showSoftwareInfoModal: true, activeSoftwareInstallation: guid });
    }

    render() {
        const { Text } = Typography;

        if(this.state.loadingOverview || this.state.domain === null) {
            return <div className='text-center'><Loading /></div>;
        }

        const { domain } = this.state;
        const { ip_address, ssl_certificate, software, health, metrics, whois, hosting_account } = domain;

        let ssl_certificate_info = <Alert type='info' showIcon message='SSL certificate not installed!' />;
        let ssl_certificate_extras = <AccessItem scopes={['can_install_ssl_certificates']}>
            <Tooltip placement='left' title='Install SSL certificate to this domain'>
                <Button size='small' icon={<PlusOutlined />} onClick={() => this.setState({ showSSLCertificateInstallModal: true })}>Install</Button>
            </Tooltip>
        </AccessItem>;

        if(ssl_certificate !== null) {
            ssl_certificate_extras = <AccessItem scopes={['can_uninstall_ssl_certificates']}><Popconfirm
                onConfirm={() => this.uninstallSSLCertificate()}
                placement='topRight'
                title="Are you sure you wish to uninstall this SSL certificate?"
                okText="Uninstall"
                disabled={ssl_certificate.status !== 'ACTIVE'}
                cancelText="Cancel">
                <Tooltip placement='left' title='Uninstall SSL certificate from this domain'>
                    <Button size='small' disabled={ssl_certificate.status !== 'ACTIVE'} icon={<DeleteOutlined />} danger>Uninstall SSL Certificate</Button>
                </Tooltip>
            </Popconfirm></AccessItem>;

            ssl_certificate_info = <Descriptions bordered size='small' column={1}>
                <Descriptions.Item style={{width: '50%'}} label='Type'>{ssl_certificate.certificate_type === 'LETS_ENCRYPT' ? 'Let\'s Encrypt' : 'Custom'}</Descriptions.Item>
                <Descriptions.Item label='Created'><DateTimeFormat value={ssl_certificate.date_created} /></Descriptions.Item>
                <Descriptions.Item label='Status'><StatusIndicator el='badge' status={ssl_certificate.status} /></Descriptions.Item>
            </Descriptions>;
        }

        let software_info = <Alert type='info' showIcon message='Software is not installed!' />;

        let software_extras = <AccessItem scopes={['can_install_software']}>
            <Space>
                <Button size='small' disabled={this.state.importingSoftware} icon={<PlusOutlined />} onClick={() => this.setState({ showSoftwareInstallModal: true })}>New Software Installation</Button>
                <Popconfirm
                    onConfirm={() => this.importSoftware()}
                    placement='topRight'
                    title={<span><b>Are you sure you wish to import software?</b><br />We will scan your hosting account for installation and if found,<br /> we will associate it with your domain.</span>}
                    body
                    okText="Import"
                    cancelText="Cancel">
                    <Tooltip placement='left' title='Import software manually from hosting account'>
                        <Button size='small' disabled={this.state.importingSoftware}
                                loading={this.state.importingSoftware} icon={<PlusOutlined />}> Import Software</Button>
                    </Tooltip>
                </Popconfirm>
            </Space>
        </AccessItem>;

        if(software !== null) {
            software_extras = <AccessItem scopes={['can_uninstall_software']}>
                <Popconfirm
                    onConfirm={() => this.uninstallSoftware()}
                    placement='topRight'
                    title="Are you sure you wish to uninstall software?"
                    okText="Uninstall"
                    disabled={software.status !== 'ACTIVE'}
                    cancelText="Cancel">
                    <Tooltip placement='left' title={'Uninstall ' + software.software_name}>
                        <Button size='small' disabled={software.status !== 'ACTIVE'} icon={<DeleteOutlined />} danger>Uninstall Software</Button>
                    </Tooltip>
                </Popconfirm>
            </AccessItem>;

            software_info = <Descriptions bordered size='small' column={1}>
                <Descriptions.Item style={{width: '50%'}} label='Software'>{software.software_name}</Descriptions.Item>
                <Descriptions.Item label='Installed'><DateTimeFormat value={software.date_installed} /></Descriptions.Item>
                <Descriptions.Item label='Status'><StatusIndicator el='badge' status={software.status} /></Descriptions.Item>
                <Descriptions.Item label='Actions'>
                    <Space>
                        <Tooltip title={'Log into ' + software.software_name}>
                            <Button size='small' disabled={software.status !== 'ACTIVE' || !hasPermission(this.props.company.permissions, ['can_login_to_software'])}
                                    icon={<LoginOutlined />} onClick={() => this.props.loginToSoftware(this.props.guid)}>Log Into Software</Button>
                        </Tooltip>
                        <Tooltip title='View installation info'>
                            <Button size='small' disabled={software.status !== 'ACTIVE'} icon={<UserOutlined />} onClick={() => this.viewSoftwareInfo(software.guid)}>Info</Button>
                        </Tooltip>
                    </Space>
                </Descriptions.Item>
            </Descriptions>;
        }

        let health_info = <Alert type='info' showIcon message='Health not yet checked!' />;

        if(typeof health !== 'undefined' && health !== null && health.date_health_checked !== null) {
            let resolved_ip_address;

            if(health.resolved_ip_address) {
                if(health.resolved_ip_address !== ip_address.ip_address) {
                    resolved_ip_address = <Text type='danger'>{health.resolved_ip_address}</Text>;
                } else {
                    resolved_ip_address = health.resolved_ip_address;
                }
            } else {
                resolved_ip_address = <Text type='danger'>Did not resolve!</Text>;
            }

            let www_resolved_ip_address;
            if(health.resolved_www_ip_address) {
                if(health.resolved_www_ip_address !== ip_address.ip_address) {
                    www_resolved_ip_address = <Text type='danger'>{health.resolved_www_ip_address}</Text>;
                } else {
                    www_resolved_ip_address = health.resolved_www_ip_address;
                }
            } else {
                www_resolved_ip_address = <Text type='danger'>Did not resolve!</Text>;
            }

            health_info = <Descriptions bordered size='small' column={1}>
                <Descriptions.Item style={{width: '50%'}} label='Last check'>
                    <DateTimeFormat value={health.date_health_checked} />
                </Descriptions.Item>
                <Descriptions.Item label='Resolved IP address'>{resolved_ip_address}</Descriptions.Item>
                <Descriptions.Item label='Resolved IP address (www)'>{www_resolved_ip_address}</Descriptions.Item>
                <Descriptions.Item label='Expired'>{health.is_expired ? <Text type='danger'>Yes</Text> : 'No'}</Descriptions.Item>
                <Descriptions.Item label='Cloudflare'>{health.is_cloudflare ? 'Yes' : 'No'}</Descriptions.Item>
                <Descriptions.Item label='NXDomain'>{health.is_nxdomain ? <Text type='danger'>Yes</Text> : 'No'}</Descriptions.Item>
            </Descriptions>;
        }

        let metrics_info = <Alert type='info' showIcon message='Metrics not yet checked!' />;
        if(typeof metrics !== 'undefined' && metrics !== null && metrics.date_metrics_checked !== null) {
            metrics_info = <Descriptions bordered size='small' column={1}>
                <Descriptions.Item style={{width: '50%'}} label='Last check'>
                    <DateFormat value={metrics.date_metrics_checked} />
                </Descriptions.Item>
                <Descriptions.Item label='DA'>{metrics.da}</Descriptions.Item>
                <Descriptions.Item label='TF'>{metrics.tf}</Descriptions.Item>
            </Descriptions>;
        }

        let google_safe_browsing = '';
        if(typeof domain.google_safe_browsing !== 'undefined' && domain.google_safe_browsing !== null && domain.google_safe_browsing.status !== null) {
            google_safe_browsing = <Descriptions bordered size='small' column={1}>
                <Descriptions.Item label='Checked'><DateTimeFormat value={domain.google_safe_browsing.date_last_check}/></Descriptions.Item>
                <Descriptions.Item label='Status'><Text type="danger">{domain.google_safe_browsing.status.join(', ')}</Text></Descriptions.Item>
                <Descriptions.Item label='Expires' style={{width: '40%'}}><DateTimeFormat value={domain.google_safe_browsing.date_status_Expires}/></Descriptions.Item>
            </Descriptions>
        }

        let domain_actions_available = ['ACTIVE', 'SUSPENDED'].indexOf(domain.status) > -1;
        let delete_domain = <AccessItem scopes={['can_delete_domains']}>
            <Popconfirm
                onConfirm={() => this.deleteDomain()}
                placement='topRight'
                title="Are you sure you want to delete this domain?"
                okText="Delete"
                okType='danger'
                disabled={!domain_actions_available}
                cancelText="Cancel">
                <Tooltip placement='left' title='Delete this domain'>
                    <Button size='small' disabled={!domain_actions_available} icon={<DeleteOutlined />} danger />
                </Tooltip>
            </Popconfirm>
        </AccessItem>;

        let hosting_ip_address = hosting_account.ip_address;
        if(domain.hosting_account.account_type === 'CUSTOM_HOST') {
            hosting_ip_address = hosting_account.custom_host_server.ip_address;
        }

        let hosting_hostname = hosting_account.hostname;
        if(domain.hosting_account.account_type === 'CUSTOM_HOST') {
            hosting_hostname = hosting_account.custom_host_server.hostname;
        }

        let hosting_info = <AccessItem scopes={['can_view_hosting']}><Descriptions bordered size='small' column={1}>
            <Descriptions.Item label='Name' style={{width: '50%'}}>{hosting_account.name}</Descriptions.Item>
            <Descriptions.Item label='Type'>{normalizeEnum(hosting_account.account_type)}</Descriptions.Item>
            {hosting_account.account_type === 'CUSTOM_HOST' ? <Descriptions.Item label='Destination type'>{normalizeEnum(hosting_account.custom_host_server.destination_type)}</Descriptions.Item> : ''}
            {hosting_account.account_type === 'CUSTOM_HOST' && hosting_account.custom_host_server.destination_type === 'HOSTNAME' ? <Descriptions.Item label='Hostname'>{hosting_hostname}</Descriptions.Item> : ''}
            {hosting_account.account_type === 'CUSTOM_HOST' && hosting_account.custom_host_server.destination_type === 'IP_ADDRESS' ? <Descriptions.Item label='IP address'>{hosting_ip_address}</Descriptions.Item> : ''}
            {hosting_account.account_type === 'SHARED_HOSTING' ? <Descriptions.Item label='Hostname'>{hosting_hostname}</Descriptions.Item> : ''}
            {hosting_account.account_type === 'SHARED_HOSTING' ? <Descriptions.Item label='IP address'>{hosting_ip_address}</Descriptions.Item> : ''}
            <Descriptions.Item label='Status'><StatusIndicator el='badge' status={hosting_account.status} /></Descriptions.Item>
            {hosting_account.account_type === 'SHARED_HOSTING' ?
                <Descriptions.Item label='Actions'>
                    <Space>
                        <AccessItem scopes={['can_login_to_hosting_accounts']}>
                            <Tooltip title='Login to your hosting account'>
                                <Button size='small' disabled={hosting_account.status !== 'ACTIVE'} icon={<LoginOutlined />} onClick={() => this.props.loginTocPanel(this.props.guid)}>Login to cPanel</Button>
                            </Tooltip>
                        </AccessItem>
                        <AccessItem scopes={['can_edit_domains']}>
                            <Tooltip title='Manage your hosting account'>
                                <Button size='small' disabled={hosting_account.status !== 'ACTIVE'} icon={<EditOutlined />} onClick={() => this.props.history.push('/hosting/' + hosting_account.guid)}>Manage</Button>
                            </Tooltip>
                        </AccessItem>
                    </Space>
                </Descriptions.Item>
            : ''}
        </Descriptions></AccessItem>;

        let ssl_pending_description;
        if(typeof ssl_certificate !== 'undefined' && ssl_certificate !== null && ssl_certificate.status === 'PENDING') {
            if(typeof health !== 'undefined' && health !== null && health.date_health_checked !== null) {

                let show_ssl_health_warning = false;

                if(health.resolved_ip_address && health.resolved_ip_address !== ip_address.ip_address) {
                    show_ssl_health_warning = true;
                }

                if(health.resolved_www_ip_address && health.resolved_www_ip_address !== ip_address.ip_address) {
                    show_ssl_health_warning = true;
                }

                if(show_ssl_health_warning) {
                    ssl_pending_description = <Alert
                        style={{marginBottom: '10px'}} type='warning' showIcon
                        message={'Waiting for your domain to resolve to assigned IP address (' + ip_address.ip_address + ')!'} />;
                }
            }
        }

        return(
            <AccessGate scopes={['can_view_domains']}>
                <Card bordered={false} size='small' title='Domain' extra={delete_domain} loading={this.state.deletingDomain}>
                    <Descriptions bordered size='small' layout='horizontal' column={1}>
                        <Descriptions.Item style={{width: '50%'}} label='Domain'>{domain.domain}</Descriptions.Item>
                        <Descriptions.Item label='Status'><StatusIndicator el='badge' status={domain.status} /></Descriptions.Item>
                        <Descriptions.Item label='Type'>{domain.is_subdomain ? 'Subdomain' : 'Domain'}</Descriptions.Item>
                        {!domain.is_subdomain ? <Descriptions.Item label='Whois expires'>{whois && whois.date_whois_checked !== null ? <DateFormat value={whois.whois_expires} /> : <span>Not checked, yet!</span>}</Descriptions.Item> : ''}
                    </Descriptions>
                </Card>
                {typeof domain.google_safe_browsing !== 'undefined' && domain.google_safe_browsing !== null && domain.google_safe_browsing.status !== null ? <Card style={{backgroundColor: '#ffe0e0'}} title="Google Safe Browsing" bordered={false} size='small'>
                    {google_safe_browsing}
                </Card>: ''}
                <Card title="IP Address" bordered={false} size='small'
                      extra={<AccessItem scopes={['can_edit_ip_addresses']}>
                          <Tooltip placement='left' title={'Modify domain\'s IP address'}>
                              <Button size='small' icon={<EditOutlined />} onClick={() => this.setState({ showIPAddressModifyModal: true })}>Modify</Button>
                          </Tooltip>
                      </AccessItem>}>
                    <Descriptions bordered size='small' column={1}>
                        <Descriptions.Item style={{width: '50%'}} label='IP address'>{ip_address.ip_address}</Descriptions.Item>
                        <Descriptions.Item label='Type'>{normalizeEnum(ip_address.ip_address_type)}</Descriptions.Item>
                        <Descriptions.Item label='Location'>{ip_address.city + ', ' + ip_address.country}</Descriptions.Item>
                        <Descriptions.Item label='Protocol'>{ip_address.http_version === 1 ? 'HTTP/1.1' : 'HTTP/2'}</Descriptions.Item>
                        <Descriptions.Item label='Status'>
                            <StatusIndicator el='badge' status={ip_address.status} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Order'><Link to={'/billing/orders/' + ip_address.order.order_id}>#{ip_address.order.order_id}</Link></Descriptions.Item>
                        <Descriptions.Item label='Renews'><DateFormat value={ip_address.order.renewal_date} /></Descriptions.Item>
                    </Descriptions>
                </Card>
                <Card title="Hosting Account" bordered={false} size='small' extra={<AccessItem scopes={['can_edit_domains']}>
                    <Tooltip placement='left' title={domain.status !== 'ACTIVE' ? 'This domain is currently busy!' : 'Change the domain\'s hosting account'}>
                        <Button disabled={domain.status !== 'ACTIVE'} onClick={() => this.setState({ showHostingAccountModifyModal: true })} size='small' icon={<EditOutlined />}>Modify</Button>
                    </Tooltip>
                </AccessItem>}>
                    {hosting_info}
                </Card>
                <Card title="SSL Certificate" loading={this.state.updatingSSLCertificate} bordered={false} size='small' extra={ssl_certificate_extras}>
                    {ssl_pending_description}
                    {ssl_certificate_info}
                </Card>
                <Card hidden={domain.hosting_account.account_type === 'CUSTOM_HOST'} title="Software" bordered={false} size='small' extra={software_extras}>
                    {software_info}
                </Card>
                {!domain.is_subdomain ? <Card title="Health" bordered={false} size='small'>
                    {health_info}
                </Card>: ''}
                {!domain.is_subdomain ? <Card title="Metrics" bordered={false} size='small'>
                    {metrics_info}
                </Card>: ''}

                <SSLCertificateInstallModal
                    domain={this.props.guid}
                    domainName={domain.domain}
                    domainType={domain.is_subdomain ? 'SUBDOMAIN' : 'DOMAIN'}
                    showModal={this.state.showSSLCertificateInstallModal}
                    closeModal={() => this.setState({ showSSLCertificateInstallModal: false })}
                    loadOverview={() => this.loadDomainDetail()} />

                <SoftwareInstallModal
                    domain={this.props.guid}
                    showModal={this.state.showSoftwareInstallModal}
                    closeModal={() => this.setState({ showSoftwareInstallModal: false })}
                    loadOverview={() => this.loadDomainDetail()} />

                <IPAddressModifyModal
                    domain={this.props.guid}
                    showModal={this.state.showIPAddressModifyModal}
                    closeModal={() => this.setState({ showIPAddressModifyModal: false })}
                    ipAddress={ip_address}
                    httpVersion={ip_address.http_version}
                    loadOverview={() => {
                        this.loadDomainDetail();
                        this.props.reloadDomains();
                    }} />

                <SoftwareInfoModal
                    showModal={this.state.showSoftwareInfoModal}
                    close={() => this.setState({ showSoftwareInfoModal: false, activeSoftwareInstallation: '' })}
                    domain={domain.guid} />

                <HostingAccountModifyModal
                    showModal={this.state.showHostingAccountModifyModal}
                    activeHostingAccount={this.state.domain.hosting_account.guid}
                    domain={this.props.guid}
                    loadOverview={() => this.loadDomainDetail()}
                    close={() => this.setState({ showHostingAccountModifyModal: false })} />
            </AccessGate>
        );
    }
}

const mapStateToProps = state => ({
    company: state.auth.user.user.company
});


export default connect(mapStateToProps, { loadDomainDetail, uninstallSSLCertificate,
    uninstallSoftware, deleteDomain, importSoftwareInstallation })(DomainOverview);