import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Alert, Checkbox, Descriptions, Form, Input, message, Modal, Select, Space, Typography} from "antd";
import Button from "antd/es/button";
import {submitPayment} from "../../actions/billing";
import {displayErrors} from "../../libs/utils";

class InvoicePaymentModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            invoice: props.invoice,
            paymentMethod: 'CARD',
            payFull: true,
            defaultPaymentAmount: props.amount,
            totalAmount: props.amount,
            currency: props.currency,
            loading: false,
            showBankTransferModal: false,
            selectedCoin: 'USDC'
        };
    }

    submitPayment() {
        if(this.state.paymentMethod === 'BANK_TRANSFER') {
            this.setState({ showBankTransferModal: true });
        } else {
            this.setState({ loading: true });

            let paymentAmount = this.state.paymentAmount;

            if(paymentAmount === '' || paymentAmount === null || typeof paymentAmount === 'undefined') {
                paymentAmount = this.props.amount;
            }

            let data = {
                payment_method: this.state.paymentMethod,
                payment_amount: paymentAmount,
                invoice_id: this.state.invoice
            };

            if(this.state.paymentMethod === 'BINANCE_PAY' || this.state.paymentMethod === 'CRYPTOMUS') {
                data['cryptocurrency'] = this.state.selectedCoin;
            }

            this.props.submitPayment(data, (res) => {
                if(this.state.paymentMethod === 'PAYPAL') {
                    message.success('Redirecting to Paypal...');
                    window.location.href = res.data.redirect_url;
                    this.setState({loading: false});
                } else if(this.state.paymentMethod === 'BINANCE_PAY') {
                    message.success('Redirecting to Binance Pay...');
                    window.location.href = res.data.redirect_url;
                } else if(this.state.paymentMethod === 'CRYPTOMUS') {
                    message.success('Redirecting to Cryptomus...');
                    window.location.href = res.data.redirect_url;
                } else {
                    this.setState({ loading: false });
                    window.location.href = '/billing/invoices/' + this.state.invoice + '?payment=success';

                    //this.props.reloadInvoice();
                    //this.closeModal();
                }
            }, (err) => {
                this.setState({ loading: false });

                if(typeof err.response !== 'undefined') {
                    displayErrors(err.response.data);
                }
            });
        }
    }

    setPaymentAmount(value) {
        let paymentAmount = value.trim();

        if(paymentAmount === '') {
            paymentAmount = this.state.totalAmount;
        } else if(value <= 0) {
            paymentAmount = this.state.totalAmount;
        } else if(value > this.state.totalAmount) {
            paymentAmount = this.state.totalAmount;
        }

        this.setState({ paymentAmount });
    }

    closeModal() {
        this.setState({
            invoice: this.props.invoice,
            paymentMethod: 'CARD',
            payFull: true,
            paymentAmount: this.props.amount,
            totalAmount: this.props.amount,
            currency: this.props.currency,
            loading: false,
            showBankTransferModal: false
        });

        this.props.close();
    }

    updatePaymentMethod(value) {
        let data = {
            paymentMethod: value,
        }

        if(value === 'BINANCE_PAY' || value === 'CRYPTOMUS') {
            data['payFull'] = true;
            data['paymentAmount'] = this.state.totalAmount;

            if(value === 'BINANCE_PAY') {
                data['selectedCoin'] = 'USDC';
            } else if(value === 'CRYPTOMUS') {
                data['selectedCoin'] = 'USDT';
            }
        }

        this.setState(data);
    }

    render() {
        const { Option } = Select;
        const { Paragraph } = Typography;

        const binanceCryptoCoins = {
            "XNO": "Nano",
            "SANTOS": "Santos FC Fan Token",
            "BCH": "Bitcoin Cash",
            "SEI": "Sei Network",
            "S": "Secret",
            "SFP": "SafePal",
            "ACH": "Alchemy Pay",
            "BEL": "Bella Protocol",
            "ACT": "Achain",
            "WOO": "WOO Network",
            "1MBABYDOGE": "Baby Doge",
            "ADA": "Cardano",
            "CHR": "Chromia",
            "CHZ": "Chiliz",
            "XRP": "Ripple",
            "FLOKI": "Floki Inu",
            "TKO": "Tokocrypto",
            "UNI": "Uniswap",
            "XTZ": "Tezos",
            "EOS": "EOS.IO",
            "HOOK": "Hooked Protocol",
            "SLF": "Solflare",
            "XVS": "Venus",
            "SLP": "Smooth Love Potion",
            "LAZIO": "Lazio Fan Token",
            "DOT": "Polkadot",
            "1INCH": "1inch",
            "TON": "The Open Network",
            "BEAMX": "BeamX",
            "ATOM": "Cosmos",
            "SOL": "Solana",
            "ETC": "Ethereum Classic",
            "BNB": "Binance Coin",
            "CGPT": "CryptoGPT",
            "ETH": "Ethereum",
            "NEO": "Neo",
            "MANTA": "Manta Network",
            "HIGH": "Highstreet",
            "ALT": "Altura",
            "BNX": "BinaryX",
            "TRX": "TRON",
            "EUR": "Euro",
            "EGLD": "Elrond",
            "TRUMP": "Trump",
            "OM": "MANTRA DAO",
            "APE": "ApeCoin",
            "ARPA": "ARPA Chain",
            "APT": "Aptos",
            "IOTX": "IoTeX",
            "SHIB": "Shiba Inu",
            "MBOX": "MOBOX",
            "CAKE": "PancakeSwap",
            "BSW": "Biswap",
            "POL": "Proof of Liquidity",
            "BTC": "Bitcoin",
            "LOKA": "League of Kingdoms",
            "RONIN": "Ronin",
            "ONT": "Ontology",
            "BTTC": "BitTorrent",
            "FIRO": "Firo",
            "MANA": "Decentraland",
            "ZEC": "Zcash",
            "XAI": "Sai",
            "DOGE": "Dogecoin",
            "SXP": "Swipe",
            "HBAR": "Hedera Hashgraph",
            "PEPE": "Pepe",
            "DOGS": "Dogs Of Elon",
            "CTSI": "Cartesi",
            "OSMO": "Osmosis",
            "AVA": "Travala.com",
            "STRAX": "Stratis",
            "NOT": "Notional",
            "EURI": "Euro",
            "BONK": "Bonk",
            "HMSTR": "Hamster",
            "ZIL": "Zilliqa",
            "AXS": "Axie Infinity",
            "ALPINE": "Alpine F1 Team Fan Token",
            "BANANA": "ApeSwap Finance",
            "NEIRO": "Neironix",
            "PORTO": "Porto Fan Token",
            "VET": "VeChain",
            "EDU": "EduCoin",
            "LTC": "Litecoin",
            "USDC": "USD Coin",
            "INJ": "Injective Protocol",
            "XLM": "Stellar",
            "LINK": "Chainlink",
            "QTUM": "Qtum",
            "FIL": "Filecoin",
            "GALA": "Gala",
            "COTI": "COTI"
        }
        const cryptomusCryptoCoins = {
            "BCH": "Bitcoin Cash",
            "BTC": "Bitcoin",
            "DASH": "Dash",
            "DOGE": "Dogecoin",
            "ETH": "Ethereum",
            "LTC": "Litecoin",
            "SOL": "Solana",
            "TON": "Toncoin",
            "USDT": "Tether",
            "USDC": "USD Coin",
            "XMR": "Monero"
        }

        let coinList = null;

        if(this.state.paymentMethod === 'BINANCE_PAY') {
            coinList = binanceCryptoCoins;
        } else if(this.state.paymentMethod === 'CRYPTOMUS') {
            coinList = cryptomusCryptoCoins;
        }

        return(
            <Fragment>
                <Modal
                    centered
                    title={'Pay Invoice'}
                    open={this.props.showModal}
                    destroyOnClose={true}
                    closable={false}
                    onCancel={() => this.closeModal()}
                    footer={
                        <Space>
                            <Button onClick={() => this.closeModal()} disabled={this.state.loading}>Close</Button>
                            <Button type='primary' onClick={() => this.submitPayment()} disabled={this.state.loading}>{this.state.paymentMethod === 'BANK_TRANSFER' ? 'View Details' : 'Pay Now'}</Button>
                        </Space>
                    }
                    confirmLoading={this.state.loading}>
                    {this.state.paymentMethod === 'BINANCE_PAY' || this.state.paymentMethod === 'CRYPTOMUS' ? <><Alert type='warning' showIcon message={<span><b>Attention!</b> It is crucial that you transfer <b>the exact amount</b> displayed on the payment page. Sending a different amount will result in processing errors and could delay your transaction or cause loss of funds!</span>} /><br /></> : ''}
                    <Form layout='vertical'>
                        <Form.Item>
                            <Checkbox disabled={this.state.paymentMethod === 'BINANCE_PAY' || this.state.paymentMethod === 'CRYPTOMUS' } checked={this.state.payFull} onChange={(e) => this.setState({ payFull: e.target.checked, paymentAmount: e.target.checked ? this.state.totalAmount : this.state.paymentAmount })}>Pay invoice in full</Checkbox>
                        </Form.Item>
                        <Form.Item label='Payment method'>
                            <Select defaultValue={this.state.paymentMethod} onChange={(value) => this.updatePaymentMethod(value)}>
                                <Option value='CARD'>Credit card</Option>
                                <Option value='PAYPAL'>PayPal</Option>
                                <Option value='BANK_TRANSFER'>Bank transfer</Option>
                                <Option value='CRYPTOMUS'>Cryptomus</Option>
                                <Option value='BINANCE_PAY'>Binance Pay</Option>
                            </Select>
                        </Form.Item>
                        {this.state.paymentMethod === 'BINANCE_PAY' || this.state.paymentMethod === 'CRYPTOMUS' ? <Form.Item label='Select currency:'>
                            <Select defaultValue={this.state.selectedCoin} showSearch
                                    filterOption={(input, option) => {
                                        if(typeof option !== 'undefined') {
                                            return option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        }
                                        return false;
                                    }}
                                    onChange={(value) => this.setState({ selectedCoin: value})}>
                                {Object.keys(coinList).map((coin) => <Option key={coin} value={coin}>{coinList[coin]} ({coin})</Option>)}
                            </Select>
                        </Form.Item> : ''}
                        <Form.Item label='Total payment'>
                            <Input value={this.state.paymentAmount} defaultValue={this.state.defaultPaymentAmount} disabled={this.state.payFull}
                                   onChange={(e) => this.setPaymentAmount(e.target.value)} />
                        </Form.Item>
                        <Form.Item>
                            <Descriptions bordered size='small' column={1} label='Payment method limits'>
                                <Descriptions.Item label='PayPal'>Up to 5000.00 USD/EUR</Descriptions.Item>
                                <Descriptions.Item label='Credit card'>From 0.50 to 3000.00 USD/EUR</Descriptions.Item>
                                <Descriptions.Item label='Bank transfer'>No limit</Descriptions.Item>
                                <Descriptions.Item label='Cryptomus'>No limit</Descriptions.Item>
                                <Descriptions.Item label='Binance Pay'>No limit</Descriptions.Item>
                            </Descriptions>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal destroyOnClose={true} closable={false} centered
                       width='600px' footer={<Button onClick={() => this.setState({ showBankTransferModal: false })}>Close</Button>}
                       open={this.state.showBankTransferModal}>
                    <p>For bank transfer, please use following details:</p>
                    <Descriptions bordered size='small' layout='horizontal' column={1}>
                        <Descriptions.Item label='Beneficiary name'>Priority Prospect OÜ</Descriptions.Item>
                        <Descriptions.Item label='IBAN'>EE412200221064329810</Descriptions.Item>
                        <Descriptions.Item label='SWIFT/BIC'>HABAEE2X</Descriptions.Item>
                        <Descriptions.Item label='Description'>Invoice {this.state.invoice}</Descriptions.Item>
                        <Descriptions.Item label='Amount'>{this.state.paymentAmount || this.state.totalAmount}</Descriptions.Item>
                        <Descriptions.Item label='Currency'>{this.state.currency}</Descriptions.Item>
                        <Descriptions.Item label={<Paragraph copyable={{ text: 'Liivalaia 8, Tallinn, Estonia, 15040' }}>Bank info</Paragraph>}>
                            <Descriptions bordered size='small' layout='horizontal' column={1}>
                                <Descriptions.Item label='Name'>Swedbank AS</Descriptions.Item>
                                <Descriptions.Item label='Address'>Liivalaia 8</Descriptions.Item>
                                <Descriptions.Item label='City'>Tallinn</Descriptions.Item>
                                <Descriptions.Item label='Country'>Estonia</Descriptions.Item>
                                <Descriptions.Item label='Postal code'>15040</Descriptions.Item>
                            </Descriptions>
                        </Descriptions.Item>
                    </Descriptions>
                </Modal>
            </Fragment>
        );
    }
}

export default connect(null, { submitPayment })(InvoicePaymentModal);