import React from "react";
import {Loading} from "../../libs/loading";
import {Modal} from "antd";
import {connect} from "react-redux";

class PageSupportModal extends React.Component {
    state = {
        loading: false,
        dataLoaded: false,
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.show && !this.state.dataLoaded && !this.state.loading) {
            this.setState({ loading: true });

            setTimeout(() => {
                this.setState({ loading: false, dataLoaded: true });
            }, 3000);
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    closeModal() {
        this.setState({ loading: false, dataLoaded: false });
        this.props.onClose();
    }

    render() {
        let content = <div className='text-center'><Loading /></div>;

        if(!this.state.loading && this.state.dataLoaded) {
            content = 'View support content for ' + this.props.supportId;
        }

        return(
            <Modal
                footer={false}
                open={this.props.show}
                centered
                onCancel={() => this.closeModal()}>
                {content}
            </Modal>
        );
    }
}

export default connect(null, {})(PageSupportModal);