import React from "react";
import {connect} from "react-redux";
import {openGettingStartedWizard} from "../../actions/getting_started_wizard";
import {Button} from "antd";
import { FaWandMagicSparkles } from "react-icons/fa6";


class GettingStartedWizardFloater extends React.Component {
    render() {
        return <div className='getting-started-wizard-floater' style={{position: 'fixed', right: 0, bottom: 0, display: 'inline', marginRight: '30px', marginBottom: '30px'}}>
            <Button type="primary" shape="round" icon={<FaWandMagicSparkles style={{marginBottom: '-2px', marginRight: '5px'}} />} onClick={() => this.props.openGettingStartedWizard()}>
                Getting Started Wizard
            </Button>
        </div>;
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    wizard: state.getting_started_wizard
});

export default connect(mapStateToProps, { openGettingStartedWizard})(GettingStartedWizardFloater);