import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {DEFAULT_PER_PAGE} from "../../config";
import {Col, ConfigProvider, Popconfirm, Row, Space, Table, Tooltip, message, Card} from "antd";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {deleteHostingAccount, loadHostingAccounts} from "../../actions/hosting";
import AddHostingModal from "../../components/hosting/AddHostingModal";
import {DateTimeFormat} from "../../components/shared/DateTimeFormat";
import {DeleteOutlined, EyeOutlined, FolderOpenOutlined} from "@ant-design/icons";
import Button from "antd/es/button";
import {Link} from "react-router-dom";
import {StatusIndicator} from "../../components/shared/Status";
import AccessGate from "../../components/permissions/AccessGate";
import AccessItem from "../../components/permissions/AccessItem";
import Flag from "react-world-flags";
import {disableDriver} from "../../actions/driver";

class HostingAccounts extends React.Component {
    state = {
        loadingAccounts: false,
        accounts: [],
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, showSizeChanger: true, total: 0 },
        showAddHostingModal: false
    };

    componentDidMount() {
        // TODO: Add support for this page
        //this.props.updatePage('Hosting Accounts', null, 'hosting-page');
        this.props.updatePage('Hosting Accounts');
        this.loadHostingAccounts();
    }

    loadHostingAccounts(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingAccounts: true });

        this.props.loadHostingAccounts(page, per_page, (response) => {
            this.setState({ accounts: response.data.data, pagination:
                    { current: response.data.page, pageSize: response.data.per_page, showSizeChanger: true, total: response.data.total }, loadingAccounts: false });
        }, (err) => {
            this.setState({ loadingAccounts: false });

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    deleteHostingAccount(guid) {
        this.setState({ loadingHostingAccounts: true });

        this.props.deleteHostingAccount(guid, () => {
            message.success('Hosting account successfully deleted!');
            this.loadHostingAccounts();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    showAddHostingAccountModal() {
        this.setState({ showAddHostingModal: true });

        this.props.disableDriver();
    }

    render() {
        const columns = [
            { title: 'Name', dataIndex: 'name', render: (item, record) => <strong><Link to={'/hosting/' + record.guid}>{item}</Link></strong> },
            { title: 'Type', align: 'center', dataIndex: 'account_type',  render: (item) => normalizeEnum(item) },
            { title: 'Location', align: 'center', dataIndex: 'location',  render: (item) => {
                if(item !== null) {
                    return <span><Flag code={item.country} height='12px' /> {item.city + ', ' + item.country}</span>;
                } else {
                    return '-';
                }
            }},
            { title: 'Domains', dataIndex: 'domain_count', align: 'center' },
            { title: 'Status', align: 'center', dataIndex: 'status',  render: (item) => <StatusIndicator status={item} el={'badge'} /> },
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} />},
            { title: '', align: 'right', render: (item, record) => <Space>
                    <Link to={'/hosting/' + record.guid}><Button size='small' icon={<EyeOutlined />}>Details</Button></Link>
                    <AccessItem scopes={['can_delete_hosting_accounts']}>
                        <Popconfirm
                            title={<span>Are you sure you want to<br />delete this hosting account?</span>}
                            onConfirm={() => this.deleteHostingAccount(record.guid)}
                            okText='Delete'
                            placement='left'
                            disabled={record.account_type !== 'CUSTOM_HOST'}
                            okButtonProps={{danger: true}}
                            cancelText='Cancel'>
                            <Button danger disabled={record.account_type !== 'CUSTOM_HOST'} size='small' icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </AccessItem>
            </Space>}
        ];

        return(
            <AccessGate scopes={['can_view_hosting_accounts']}>
                <Card size='small'>
                    <Row justify='space-between' style={{paddingBottom: '10px'}}>
                        <Col>
                            <AccessItem scopes={['can_add_hosting_accounts']}>
                                <Button type='primary' className='add-hosting-account-btn' icon={<PlusOutlined />} onClick={() => this.showAddHostingAccountModal()}>Add Hosting Account</Button>
                            </AccessItem>
                        </Col>
                        <Col><Tooltip title='Reload table'><Button type='text' icon={<UndoOutlined />} onClick={() => this.loadHostingAccounts()}/></Tooltip></Col>
                    </Row>

                    <ConfigProvider renderEmpty={() => <div style={{textAlign: 'center'}}>
                        <FolderOpenOutlined style={{fontSize: 40}}/>
                        <p>Nothing to see here...</p>
                        <AccessItem scopes={['can_add_hosting_accounts']}>
                            <Button icon={<PlusOutlined/>} onClick={() => this.setState({showAddHostingModal: true})}>Add
                                Hosting Account</Button>
                        </AccessItem>
                    </div>}>
                        <div style={{overflowX: 'auto'}}>
                            <Table
                                dataSource={this.state.accounts}
                                columns={columns}
                                rowKey={(item) => item.guid}
                                pagination={this.state.pagination}
                                onChange={(pagination) => this.loadHostingAccounts(pagination.current, pagination.pageSize)}
                                loading={this.state.loadingAccounts}/>
                        </div>
                    </ConfigProvider>
                </Card>

                <AddHostingModal
                    history={this.props.history}
                    showModal={this.state.showAddHostingModal}
                    reloadHostingAccounts={() => this.loadHostingAccounts() }
                    close={() => this.setState({ showAddHostingModal: false })} />
            </AccessGate>
        );
    }
}

export default connect(null, { updatePage, loadHostingAccounts, deleteHostingAccount,
    disableDriver })(HostingAccounts);