import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, Card, Col, Divider, Form, Input, message, Modal, Row, Switch} from "antd";
import {get2FA, loadProfile, updatePassword, updatePreferences, updateProfile} from "../../actions/account";
import {loadUser} from "../../actions/auth";
import {displayErrors} from "../../libs/utils";
import {Loading} from "../../libs/loading";
import {EyeOutlined, LockOutlined} from "@ant-design/icons";
import User2FAModal from "./User2FAModal";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import DisableUser2FAModal from "./DisableUser2FAModal";
import User2FABackupCodesModal from "./User2FABackupCodesModal";
import RetweetOutlined from "@ant-design/icons/lib/icons/RetweetOutlined";

class Profile extends React.Component {
    state = {
        loadingProfile: false,
        updatingProfile: false,

        updatingPassword: false,
        newPassword: '',
        newPasswordRepeat: '',
        oldPassword: '',

        loading2fa: false,
        user2faEnabled: false,
        show2faModal: false,
        showDisable2faModal: false,
        show2faBackupCodes: false,

        showPasswordResetModal: false,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    componentDidMount() {
        this.props.updatePage('Profile');

        this.loadUser2FA();
        this.loadProfile();
    }

    loadProfile() {
        this.setState({ loadingProfile: true });

        this.props.loadProfile((res) => {
            this.setState({ ...this.state, ...res.data, loadingProfile: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loadingProfile: false })
            }
        });
    }

    updateProfile() {
        let first_name = this.state.first_name.trim();
        let last_name = this.state.last_name.trim();

        if(first_name.length === 0) {
            return message.error('Please enter your first name!');
        }

        if(last_name.length === 0) {
            return message.error('Please enter your last name!');
        }

        this.setState({ updatingProfile: true });

        this.props.updateProfile({ first_name, last_name }, () => {
            message.success('Profile successfully updated!');

            this.setState({ updatingProfile: false });
            this.props.loadUser();

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ updatingProfile: false });
            }
        });
    }

    updatePassword() {
        this.setState({ updatingPassword: true });

        let oldPassword = this.state.oldPassword.trim();
        let newPassword = this.state.newPassword.trim();
        let newPasswordRepeat = this.state.newPasswordRepeat.trim();

        if(oldPassword.length === 0) {
            return message.error('Please enter your old password!');
        }

        if(newPassword.length === 0) {
            return message.error('Please enter your new password!');
        }

        if(newPasswordRepeat.length === 0) {
            return message.error('Please repeat your new password!');
        }

        if(newPassword !== newPasswordRepeat) {
            return message.error('New passwords do not match!');
        }

        let data = {
            old_password: oldPassword,
            new_password: newPassword,
            new_password_repeat: newPasswordRepeat
        };

        this.props.updatePassword(data, () => {
            message.success('Password successfully updated!');

            this.closePasswordResetModal();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ updatingPassword: false });
            }
        });
    }

    loadUser2FA() {
        this.setState({ loading2fa: true });

        this.props.get2FA((res) => {
            this.setState({ loading2fa: false, user2faEnabled: res.data.enabled });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading2fa: false, user2faEnabled: false });
            }
        });
    }

    closePasswordResetModal = () => {
        this.setState({ showPasswordResetModal: false, updatingPassword: false, oldPassword: '', newPassword: '', newPasswordRepeat: '' });
    }

    updatePreference(field, value) {
        let data = {
            [field]: value
        };

        this.setState(data);

        this.props.updatePreferences(data, () => {
            message.success('Preferences successfully updated!');
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        if(this.state.loadingProfile) {
            return <div className='text-center'><Loading /></div>;
        }

        return(
           <Fragment>
               <Row gutter={[16, 16]} >
                   <Col xs={24} md={12}>
                       <Card title='Profile' size='small' extra={<Button type='primary' size='small' loading={this.state.updatingProfile} onClick={() => this.updateProfile()}>Update Profile</Button>}>
                           <Form layout='vertical'>
                               <Row gutter={[16, 16]}>
                                   <Col span={12}>
                                       <Form.Item label='First name'>
                                           <Input value={this.state.first_name} disabled={this.state.updatingProfile} onChange={(e) => this.setState({ first_name: e.target.value })} />
                                       </Form.Item>
                                   </Col>
                                   <Col span={12}>
                                       <Form.Item label='Last name'>
                                           <Input value={this.state.last_name} disabled={this.state.updatingProfile} onChange={(e) => this.setState({ last_name: e.target.value })} />
                                       </Form.Item>
                                   </Col>
                               </Row>
                               <Row>
                                   <Col span={24}>
                                       <Form.Item label='Email address' extra={<small><em>Contact support to change the email address.</em></small>}>
                                           <Input value={this.state.email} name='email' disabled />
                                       </Form.Item>
                                   </Col>
                               </Row>
                           </Form>
                       </Card>
                       <br />
                       <Card title='2FA' size='small' loading={this.state.loading2fa}>
                           <Row gutter={[16, 16]} justify='space-between' align='middle'>
                               <Col>
                                   <strong>Google Authenticator</strong><br />
                                   <small>At login, you will be prompted to submit a code from Google Authenticator app.</small>
                               </Col>
                               <Col>
                                   {this.state.user2faEnabled ? <Button size='small' icon={<DeleteOutlined />} onClick={() => this.setState({ showDisable2faModal: true })} danger>Disable</Button> : ''}
                                   {!this.state.user2faEnabled ? <Button size='small' icon={<LockOutlined />} onClick={() => this.setState({ show2faModal: true })}>Enable</Button> : ''}
                               </Col>
                           </Row>
                           {this.state.user2faEnabled ? <Fragment>
                               <Divider style={{margin: '10px 0'}} />
                               <Row gutter={[16, 16]} justify='space-between'>
                                   <Col>Backup codes</Col>
                                   <Col>
                                       <Button size='small' icon={<EyeOutlined />} onClick={() => this.setState({ show2faBackupCodes: true })}>View</Button>
                                   </Col>
                               </Row>
                           </Fragment> : ''}
                       </Card>
                   </Col>
                   <Col xs={24} md={12}>
                       <Card title='Password' size='small' >
                           <Row gutter={[16, 16]} justify='space-between' align='middle'>
                               <Col>
                                   <strong>Password</strong><br />
                                   <small>Manage your account's password.</small>
                               </Col>
                               <Col>
                                   <Button size='small' icon={<RetweetOutlined />} onClick={() => this.setState({ showPasswordResetModal: true })}>Reset</Button>
                               </Col>
                           </Row>
                       </Card>
                       <br />
                       <Card title='Email Preferences' size='small' >
                           <Row gutter={[16, 16]} justify='space-between' align='middle'>
                               <Col>
                                   <strong>Failed login emails</strong><br />
                                   <small>Receive an email when a failed login is detected on your account.</small>
                               </Col>
                               <Col>
                                   <Switch size='small' checked={this.state.receive_failed_login_emails} onChange={(value) => this.updatePreference('receive_failed_login_emails', value)} />
                               </Col>
                           </Row>
                       </Card>
                   </Col>
               </Row>

               <User2FAModal
                   showModal={this.state.show2faModal}
                   closeModal={() => this.setState({ show2faModal: false })} />

               <DisableUser2FAModal
                   showModal={this.state.showDisable2faModal}
                   closeModal={() => {
                       this.setState({ showDisable2faModal: false });
                       this.loadUser2FA();
                   }}/>

               <User2FABackupCodesModal
                   showModal={this.state.show2faBackupCodes}
                   closeModal={() => this.setState({ show2faBackupCodes: false, user2faBackupCodes: ''})} />

               <Modal
                    open={this.state.showPasswordResetModal}
                    title='Reset Password'
                    onCancel={() => this.closePasswordResetModal()}
                    footer={<div>
                        <Button onClick={() => this.closePasswordResetModal()}>Cancel</Button>
                        <Button type='primary' loading={this.state.updatingPassword} onClick={() => this.updatePassword()}>Change Password</Button>
                    </div>}
               >
                   <Form layout='vertical'>
                       <Form.Item label='Old password'>
                           <Input type='password' value={this.state.oldPassword} disabled={this.state.updatingPassword} onChange={(e) => this.setState({ oldPassword: e.target.value })}/>
                       </Form.Item>
                       <Form.Item label='New password'>
                           <Input type='password' value={this.state.newPassword} disabled={this.state.updatingPassword} onChange={(e) => this.setState({ newPassword: e.target.value })}/>
                       </Form.Item>
                       <Form.Item label='Repeat new password'>
                           <Input type='password' value={this.state.newPasswordRepeat} disabled={this.state.updatingPassword} onChange={(e) => this.setState({ newPasswordRepeat: e.target.value })}/>
                       </Form.Item>
                   </Form>
               </Modal>
           </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
});

export default connect(mapStateToProps, { updatePage, loadProfile, updateProfile, loadUser,
    updatePassword, get2FA, updatePreferences })(Profile);