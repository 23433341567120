import {PAGE_UPDATED, PAGE_LOADING, PAGE_WHITE_LABEL_DATA_UPDATED} from "../actions/types";

const initialState = {
    isLoading: false,
    title: '',
    subtitle: '',
    whiteLabel: null,
    supportId: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch(action.type) {
        case PAGE_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case PAGE_UPDATED:
            return {
                ...state,
                isLoading: false,
                title: action.payload.title,
                subtitle: action.payload.subtitle,
                supportId: action.payload.supportId,
            };
        case PAGE_WHITE_LABEL_DATA_UPDATED:
            return {
                ...state,
                whiteLabel: action.payload
            }
        default:
            return state;
    }
}