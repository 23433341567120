import React from 'react';
import { connect } from 'react-redux';
import {Button, Card, Checkbox, Col, Form, Input, message, Row, Select, Tooltip} from "antd";
import {Link} from "react-router-dom";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import countryList from 'react-select-country-list'
import {registerAccount, validateVAT} from "../../actions/auth";
import {displayErrors, getCookieValue} from "../../libs/utils";
import {updatePage} from "../../actions/page";

class Register extends React.Component  {
    state = {
        step: 1,

        email: '',
        password: '',
        first_name: '',
        last_name: '',
        company_name: '',
        address1: '',
        address2: '',
        city: '',
        region: '',
        postal_code: '',
        country: '',
        currency: 'USD',
        found_us: '',
        found_us_selection: '',
        vat_id: '',

        invalid_company: false,
        vat_is_valid: true,

        agreeTerms: false,

        validatingInitialData: false,

        passwordInputType: 'password'
    };

    countries = countryList().getData();

    componentDidMount() {
        this.props.updatePage('Register');
    }

    nextStep() {
        if(this.state.step === 1) {
            if(this.state.email.trim().length === 0) {
                return message.error('Please enter your email address!');
            }

            if(this.state.password.trim().length === 0) {
                return message.error('Please enter your password!');
            }

            if(this.state.vat_id.trim().length !== 0) {
                this.setState({ validatingInitialData: true });

                let data = {
                    'vat_id': this.state.vat_id,
                    'company_name': this.state.company_name
                };

                this.props.validateVAT(data, (res) => {
                    if(res.data.is_valid) {
                        this.setState({ step: 2, invalid_company: false, vat_is_valid: true, validatingInitialData: false });
                    } else {
                        if(res.data.invalid_company) {
                            this.setState({ invalid_company: true, vat_is_valid: true, validatingInitialData: false });
                        } else if(!res.data.is_valid) {
                            this.setState({ invalid_company: false, vat_is_valid: false, validatingInitialData: false });
                        }
                    }
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                        this.setState({ loading: false, validatingInitialData: false });
                    }
                });
            } else {
                this.setState({ step: 2 });
            }
        } else if(this.state.step === 2) {
            this.setState({ step: 3 });
        } else if(this.state.step === 3) {
            this.setState({ step: 4 });
        } else if(this.state.step === 4) {
            this.setState({ step: 5 });
        }
    }

    previousStep() {
        if(this.state.step === 2) {
            this.setState({ step: 1 });
        } else if(this.state.step === 3) {
            this.setState({ step: 2 });
        } else if(this.state.step === 4) {
            this.setState({ step: 3 });
        } else if(this.state.step === 5) {
            this.setState({ step: 4 });
        }
    }

    createAccount() {
        let email = this.state.email.trim();
        let password = this.state.password.trim();
        let first_name = this.state.first_name.trim();
        let last_name = this.state.last_name.trim();
        let company_name = this.state.company_name.trim();
        let address1 = this.state.address1.trim();
        let address2 = this.state.address2.trim();
        let city = this.state.city.trim();
        let region = this.state.region.trim();
        let postal_code = this.state.postal_code.trim();
        let country = this.state.country.trim();
        let currency = this.state.currency.trim();
        let agree_terms = this.state.agreeTerms;
        let found_us = this.state.found_us;
        let vat_id = this.state.vat_id;

        if(email.length === 0) {
            return message.error('Please enter your email address!');
        }

        if(password.length === 0) {
            return message.error('Please enter your password!');
        }

        if(first_name.length === 0) {
            return message.error('Please enter your first name!');
        }

        if(last_name.length === 0) {
            return message.error('Please enter your last name!');
        }

        if(company_name.length === 0) {
            return message.error('Please enter your company name!');
        }

        if(address1.length === 0) {
            return message.error('Please enter your address!');
        }

        if(city.length === 0) {
            return message.error('Please enter your city!');
        }

        if(region.length === 0) {
            return message.error('Please enter your region!');
        }

        if(postal_code.length === 0) {
            return message.error('Please enter your postal code!');
        }

        if(country.length === 0) {
            return message.error('Please enter your country!');
        }

        if(currency.length === 0) {
            return message.error('Please enter your currency!');
        }

        if(found_us.length === 0) {
            return message.error('Please describe how did you find us!');
        }

        let data = {
            email, password, first_name, last_name, company_name, address1, address2,
            city, region, postal_code, country, agree_terms, currency, found_us,
            vat_id
        };

        window.location.search.substring(1).split('&').forEach(value => {
            let pieces = value.split('=');
            data[pieces[0]] = pieces[1];
        });

        if(window.location.search === '') {
            ['utm_campaign', 'utm_source', 'utm_medium', 'utm_content', 'utm_term', 'aff_click_id'].forEach(value => {
                if(getCookieValue(value) !== '') {
                    data[value] = getCookieValue(value);
                }
            })
        }

        this.setState({ loading: true });

        this.props.registerAccount(data, () => {
            this.props.history.push('/auth/login?registration=successful');
            this.setState({ loading: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false });
            }
        });
    }

    updateFoundUs(value) {
        let data = {
            found_us_selection: value
        };

        if(value !== 'OTHER') {
            data['found_us'] = value;
        } else {
            data['found_us'] = '';
        }

        this.setState(data);
    }

    validateProfileInfo() {
        if(this.state.email.trim().length === 0) {
            return message.error('Please enter your email address!');
        }

        if(!this.state.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
            return message.error('Please enter a valid email address!');
        }

        if(this.state.password.trim().length === 0) {
            return message.error('Please enter your password!');
        }

        if(this.state.first_name.trim().length === 0) {
            return message.error('Please enter your first name!');
        }

        if(this.state.last_name.trim().length === 0) {
            return message.error('Please enter your last name!');
        }

        if(this.state.company_name.trim().length < 5) {
            return message.error('Please enter a correct company name or enter your full name!');
        }

        let bad_company_names = ['no company', 'none', 'own', 'not available', 'notavailable', 'nil', 'na',
            'n/a', 'work', 'info', 'personal', 'test', 'unknown', 'sample', 'other', 'company'];

        if(bad_company_names.indexOf(this.state.company_name.trim().toLowerCase()) !== -1) {
            return message.error('If you don\'t have a company name, please enter your name!');
        }

        this.nextStep();
    }

    validateCompanyInfo() {

        if(this.state.address1.trim().length === 0) {
            return message.error('Please enter your address!');
        }

        if(this.state.city.trim().length === 0) {
            return message.error('Please enter your city!');
        }

        if(this.state.region.trim().length === 0) {
            return message.error('Please enter your region!');
        }

        if(this.state.postal_code.trim().length === 0) {
            return message.error('Please enter your postal code!');
        }

        if(this.state.country.trim().length === 0) {
            return message.error('Please enter your country!');
        }

        if(this.state.found_us_selection === 'OTHER' || this.state.found_us_selection === 'CONFERENCE' ) {
            if(this.state.found_us.trim().length === 0) {
                return message.error('Please be specific about where you found us!');
            }
        }

        this.nextStep();
    }

    render() {
        let content = '';
        const { Option } = Select;

        if(this.state.step === 1) {
            content = <Form layout='vertical'>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12}>
                        <Form.Item label='First name'>
                            <Input value={this.state.first_name} onChange={(e) => this.setState({ first_name: e.target.value })} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Last name'>
                            <Input value={this.state.last_name} onChange={(e) => this.setState({ last_name: e.target.value })} />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item label='Email'>
                    <Input value={this.state.email} type='email'
                           onChange={(e) => this.setState({ email: e.target.value })} />
                </Form.Item>
                <Form.Item label='Password'>
                    <Input value={this.state.password} type={this.state.passwordInputType}
                           onChange={(e) => this.setState({ password: e.target.value })}
                           suffix={this.state.passwordInputType === 'text' ? <Tooltip title='Hide password'><EyeInvisibleOutlined onClick={() => this.setState({ passwordInputType: 'password' })} /></Tooltip> : <Tooltip title='Show password'><EyeOutlined onClick={() => this.setState({ passwordInputType: 'text' })} /></Tooltip> }/>
                </Form.Item>
                <Form.Item label='Company name' extra={this.state.invalid_company ? <small style={{color: 'red'}}>Company name does not match the company name used for VAT registration. Please ensure that your company name <b><u>exactly</u></b> matches the company name in your country's registry.</small> : ''}>
                    <Input status={this.state.invalid_company ? 'error': ''} value={this.state.company_name} onChange={(e) => this.setState({ company_name: e.target.value })} />
                </Form.Item>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item label='VAT ID' extra={<small>Optional. Valid format: EE123456789. {!this.state.vat_is_valid ? <span style={{color: 'red'}}>Invalid VAT ID!</span> : ''}</small>}>
                            <Input status={!this.state.vat_is_valid ? 'error': ''} value={this.state.vat_id} onChange={(e) => this.setState({ vat_id: e.target.value })} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='Currency' extra={<small>Currency cannot be changed later!</small>}>
                            <Select value={this.state.currency} onChange={(value) => this.setState({ currency: value })}>
                                <Option value='USD'>USD</Option>
                                <Option value='EUR'>EUR</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Button block loading={this.state.validatingInitialData} disabled={this.state.validatingInitialData}
                        type='primary' onClick={() => this.validateProfileInfo()}>Continue</Button>
                <div className='auth-mobile-button'>
                    <Link to={'/auth/login' + window.location.search}>
                        <Button style={{ width: '100%', marginTop: '15px'}}>Login</Button>
                    </Link>
                </div>
            </Form>;
        } else if(this.state.step === 2) {
            const { Option } = Select;
            const { TextArea } = Input;

            content = <Form layout='vertical'>
                <Form.Item label='Address'>
                    <Input value={this.state.address1} onChange={(e) => this.setState({ address1: e.target.value })} />
                </Form.Item>
                <Form.Item label='Address 2'>
                    <Input value={this.state.address2} onChange={(e) => this.setState({ address2: e.target.value })} />
                </Form.Item>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12}>
                        <Form.Item label='City'>
                            <Input value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Region'>
                            <Input value={this.state.region} onChange={(e) => this.setState({ region: e.target.value })} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Country'>
                            <Select
                                placeholder='Select country'
                                showSearch
                                filterOption={(input, option) => {
                                    if(typeof option !== 'undefined') {
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }
                                    return false;
                                }}
                                onChange={(value) => this.setState({ country: value })}>
                                {this.countries.map((key) => {
                                    return <Option key={key.value} value={key.value}>{key.label}</Option>;
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Postal code'>
                            <Input value={this.state.postal_code} onChange={(e) => this.setState({ postal_code: e.target.value })} />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item label='How did you hear about us?'>
                    <Select onChange={(value) => this.updateFoundUs(value)}>
                        <Option value='LINKED_IN'>LinkedIn</Option>
                        <Option value='GOOGLE'>Google</Option>
                        <Option value='REDDIT'>Reddit</Option>
                        <Option value='FACEBOOK'>Facebook</Option>
                        <Option value='INSTAGRAM'>Instagram</Option>
                        <Option value='CONFERENCE'>Conference</Option>
                        <Option value='OTHER'>Other</Option>
                    </Select>
                </Form.Item>
                {this.state.found_us_selection === 'OTHER' || this.state.found_us_selection === 'CONFERENCE' ? <Form.Item label='Please be more specific:'>
                    <TextArea value={this.state.found_us} onChange={(e) => this.setState({ found_us: e.target.value })} />
                </Form.Item> : ''}
                <Button block type='primary' onClick={() => this.validateCompanyInfo()}>Continue</Button>
                <Button block type='default' style={{ width: '100%', marginTop: '15px'}} onClick={() => this.previousStep()}>Back</Button>

            </Form>;
        } else if(this.state.step === 3) {

            content = <div id='tos-content'>
                <h1 className='text-center'>Terms Of Service</h1>
                <div id='tos-container' className='tos-container terms-container'>
                    <p>This Terms of Service ("Agreement") is a legally binding contract between Priority Prospect
                        ("Priority Prospect OÜ") and you ("Customer," "you" or "your") that shall govern the purchase
                        and use, in any manner, of the services provided by Priority Prospect to Customer (collectively,
                        the "Services").</p>
                    <p>By purchasing and/or using the Services in any manner, you represent that you have read,
                        understand, and agree to all terms and conditions set forth in this Agreement, and that you are
                        at least eighteen (18) years old and have the legal ability to engage in a contract.</p>
                    <p>If you do not agree to all the terms and conditions set forth in this Agreement, then you may not
                        use any of the Services. If you are already a customer of Priority Prospect and do not agree
                        with the terms and conditions set forth in this Agreement, you should immediately contact
                        Priority Prospect to cancel your Services.</p>
                    <p>
                        <a id="ownership-and-services-purchased" href="#ownership-and-services-purchased">1. Ownership
                            and Services Purchased</a>
                    </p>
                    <p>1.1. The individual or entity set out in our records as the primary billing contact shall be the
                        owner of the account.</p>
                    <p>1.2. The features and details of the Services governed by this Agreement are described on the web
                        pages setting out the particular services or products you have purchased ("Service Description
                        Page") based on their description on the Service Description Page as of the Effective Date, as
                        defined below. Priority Prospect may modify the products and services it offers from
                        time-to-time. Should the Service Description Page change subsequent to the Effective Date, we
                        have no obligation to modify the Service to reflect such a change. The services and products
                        provided to you by Priority Prospect as set out on the Service Description Page, are referred to
                        as the "Services."</p>
                    <p>1.3. Certain aspects of the Services are provided by third parties. These third parties may have
                        reserved the right to make changes, including material changes, to the services provided by
                        them. You may terminate this Agreement if such a change materially affects the Services.</p>
                    <p>
                        <a id="term-of-agreement" href="#term-of-agreement">2. Term of Agreement</a>
                    </p>
                    <p>2.1. This Agreement becomes effective immediately when Customer creates an account ("Effective
                        Date") and remains effective and binding until terminated by either party as outlined below.
                        This Agreement may only be modified by a written amendment signed by an authorized executive of
                        Priority Prospect, or by the posting by Priority Prospect of a revised version.</p>
                    <p>2.2. The term of this Agreement is set to the Customer's billing term ("Term"). If no Term is set
                        out, the Term shall be one (1) year. Upon expiration of the initial Term, this Agreement shall
                        renew for periods equal to the length of the initial Term, unless one party provides notice of
                        its intent to terminate as set out in this Agreement.</p>
                    <p>
                        <a id="obeying-the-law" href="#obeying-the-law">3. Obeying the Law</a>
                    </p>
                    <p>3.1. Priority Prospect is registered and located within the Estonia and as such, we are required
                        to comply with the laws and official policies of Estonia, regardless of where the Services are
                        provided. In addition, Priority Prospect will comply with appropriate laws and official policies
                        set forth by Estonian government.</p>
                    <p>
                        <a id="payments-and-billing" href="#payments-and-billing">4. Payments and Billing</a>
                    </p>
                    <p>4.1. Priority Prospect will automatically bill your payment method on file on the due date to the
                        due date on all terms of one (1) or more years; for terms less than one (1) month in length,
                        Priority Prospect will attempt to bill your payment method on file on the due date. All fees are
                        billed in United States Dollars ("USD") or Euros ("EUR"), based on currency chosen when signed
                        up, and are subject to change with thirty (30) days notice prior notice to you.</p>
                    <p>4.2. Your "Billing Term" is the period of time you have chosen to receive bills for the Services.
                        For example, your Billing Term may be monthly, quarterly, or annually.</p>
                    <p>4.3. Priority Prospect is only able to automatically collect payment from customers with credit
                        cards stored on file (as opposed to credit cards used one for one time transactions) or active
                        PayPal preapprovals and subscriptions. All other payment methods (one time credit card payments,
                        PayPal one time payments, etc.) must be initiated manually by you. It is your obligation to
                        ensure that reoccurring fees are paid on their due date.</p>
                    <p>4.4. As a customer of Priority Prospect, it is your responsibility to ensure that all billing
                        information on file with Priority Prospect is accurate, and that any credit card or other
                        automated payment method on file has sufficient funds for processing. You are solely responsible
                        for any and all fees charged to your payment method by the issuer, bank, or financial
                        institution including, but not limited to, membership, overdraft, insufficient funds and over
                        the credit limit fees. Priority Prospect screens all orders for fraud and other unethical
                        practices. Services will not be activated until this fraud screen is completed. In certain
                        cases, if your account is flagged for fraud, third party services, such as IP allocations, will
                        not be processed. Priority Prospect has no liability for the failure to provide Services,
                        including third party services, if your account fails its fraud screen.</p>
                    <p>
                        <a id="late-payments" href="#late-payments">5. Late Payments</a>
                    </p>
                    <p>5.1. Any account not paid in full by the end of the first day of the Billing Term will be given a
                        seven (7) day grace period. If payment is not made within the seven (7) day grace period,
                        Priority Prospect reserves the right to suspend your Service(s) with Priority Prospect and to
                        charge a $15 "late penalty." Seven (7) days following suspension of Services for non-payment,
                        Priority Prospect reserves the right to terminate Service(s) for non-payment.</p>
                    <p>5.2. Priority Prospect is not responsible for any damages or losses as a result of suspension or
                        termination for non-payment of your account. In addition, Priority Prospect reserves the right
                        to refuse to re-activate your Services until any and all outstanding invoice(s) have been paid
                        in full.</p>
                    <p>
                        <a id="refund-policy-and-billing-disputes" href="#refund-policy-and-billing-disputes">6. Refund
                            Policy and Billing Disputes</a>
                    </p>
                    <p>6.1. No refunds are offered on IP addresses, custom packages, setup fees, custom discounts with a
                        commitment (for example volume discounts with yearly commitment), advanced support fees or
                        processing fees.</p>
                    <p>6.2. Refunds will be issued only to the payment method that the original payment was sent from,
                        and may take up to one (1) week to process. Eligible payments older than sixty (60) days may
                        require a refund via PayPal due to merchant account policies and procedures.</p>
                    <p>6.3. The following methods of payment are not refundable any circumstances (including during the
                        money back guarantee period, if one applies), and refunds will be posted solely as credit to the
                        hosting account for current or future Services: bank wire transfers, Western Union payments,
                        checks and money orders.</p>
                    <p>6.4. Refund may be possible when client has not used resources allocated to them within 7 days
                        after the initial order. Example: a domain has not been set up onto an IP address that client
                        rented from Priority Prospect. Due to the nature of the hosting service, no exceptions will be
                        made.</p>
                    <p>6.5. No refunds, for the remaining Service time, will be provided when customer wants to cancel
                        individual IP addresses from more than one (1) active orders.</p>
                    <p>
                        <a id="chargebacks-reversals-and-retrievals" href="#chargebacks-reversals-and-retrievals">7.
                            Chargebacks, Reversals, and Retrievals</a>
                    </p>
                    <p>7.1. If Priority Prospect receives a chargeback or payment dispute from a credit card company,
                        bank, or Paypal, your Services may be suspended without notice. A $50 chargeback fee (issued to
                        recoup mandatory fees passed on to Priority Prospect by the credit card company), plus any
                        outstanding balances accrued as a result of the chargeback(s), must be paid in full before
                        service is restored. Instead of issuing a chargeback, please contact Priority Prospect's billing
                        team to address any billing issues.</p>
                    <p>7.2. If Priority Prospect appeals a chargeback or other payment dispute and wins the dispute or
                        appeal, the funds will likely be returned to Priority Prospect by the credit card company or
                        bank. Any double payment resulting from this process will be applied to Customer's account in
                        the form of a service credit.</p>
                    <p>
                        <a id="cancellations-of-service" href="#cancellations-of-service">8. Cancellation of
                            Services</a>
                    </p>
                    <p>8.1. Either party may terminate this Agreement by providing notice to the other as provided
                        herein.</p>
                    <p>8.2. You may cancel Service(s) with Priority Prospect by submitting a cancellation request
                        from <a href="https://panel.priorityprospect.com">dashboard</a>. In the event that you are
                        unable to login to the dashboard, please contact our billing department via email and we will
                        assist you. However, Priority Prospect prefers that cancellations are submitted through the
                        dashboard to reduce the likelihood of error and ensure the security of your account. Priority
                        Prospect cannot accept cancellation requests over the telephone, email or live chat. </p>
                    <p>8.3. Cancellations must be requested via the form indicated above 48 hours or more prior to the
                        Service's renewal date. If a cancellation notice is not received within the required time frame,
                        you will be billed for the next Billing Term and are responsible for payment as set forth above.
                        Cancellations are not final until confirmed by a representative of Priority Prospect (not an
                        auto-responder of a ticket received) in writing by email.</p>
                    <p>8.4. If you pay Priority Prospect via PayPal, it is your responsibility to cancel any
                        subscription for recurring PayPal payments.</p>
                    <p>8.5. Priority Prospect may terminate this Agreement at any time by providing notice to Customers
                        via email. Should Priority Prospect terminate this Agreement for any reason other than a
                        material breach, or violation of Priority Prospect's Acceptable Use Policy, any prepaid fees
                        shall be refunded.</p>
                    <p>8.6. One party may also terminate this Agreement upon the occurrence of a material breach which
                        has not been cured by the other party within ten (10) days of their receipt of written notice of
                        the breach. For the purposes of defining a material breach, materiality shall be determined from
                        the perspective of a reasonable business person with significant experience in conducting
                        business on the Internet. Notices of material breach must contain sufficient detail for the
                        party against whom the assertion of material breach is directed to identify the breach and
                        attempt to take corrective action.</p>
                    <p>
                        <a id="refusal-of-service" href="#refusal-of-service">9. Refusal of Service</a>
                    </p>
                    <p>9.1. Priority Prospect reserves the right to refuse service to anyone at any time. Any material
                        that, in Priority Prospect's judgement, is obscene, threatening, illegal, or violates Priority
                        Prospect's terms of service in any manner may be removed from servers operated by Priority
                        Prospect (or otherwise disabled), with or without notice.</p>
                    <p>9.2. Similarly, Priority Prospect reserves the right to cancel, suspend, or otherwise restrict
                        access to the Service(s) it provides at any time, for any or no reason, and with or without
                        notice. Priority Prospect is not responsible for any damages or loss of data resulting from such
                        suspension or termination.</p>
                    <p>9.3. If any manner of communication with Priority Prospect's staff could be construed as
                        belligerent, vulgar (curse words), attacking, highly rude, threatening, or abusive, you will be
                        issued one warning. If the communication continues, your account may be suspended or terminated.
                        This includes, but is not limited to, threats to sue, slander, libel, publicly post, or initiate
                        a chargeback.</p>
                    <p>9.4. To help protect Priority Prospect and its customers from fraud, Priority Prospect may ask
                        you to provide a copy of a government issued identification and/or a scan of the credit card
                        used for the purchase. If you fail to meet these requirements, the order may be considered
                        fraudulent in nature and denied.</p>
                    <p>
                        <a id="resource-usage" href="#resource-usage">10. Resource Usage</a>
                    </p>
                    <p>10.1. Each customer is required to utilize as little server resources as possible, so as to allow
                        for reasonable performance by all Priority Prospect customers. Because server CPU and memory are
                        shared resources, excessive consumption of these resources can interfere with or completely
                        prevent normal service performance for other users. Priority Prospect reserves the right to
                        suspend or terminate Services on any account that, at its sole discretion, is abusing server
                        resources. Such suspension or termination can occur at any time without prior warning, and for
                        any or no reason.</p>
                    <p>
                        <a id="bandwidth-usage" href="#bandwidth-usage">11. Bandwidth Usage</a>
                    </p>
                    <p>11.1. There are no limits for bandwidth usage on IP addresses. Customers may use enough bandwidth
                        for their websites as it's required, but only in reasonable amounts.</p>
                    <p>11.2. Like other resources, customers are expected to demonstrate responsible usage of bandwidth
                        resources, so as to allow for reasonable performance by all Priority Prospect customers.
                        Priority Prospect regularly monitors bandwidth usage and reserve the right to suspend,
                        terminate, and/or limit (such as through port speed limiting) Services on any account, that at
                        its sole discretion, is using an abnormally large amount of bandwidth. Such suspension,
                        termination, or limitation can occur at any time without prior warning, and for any or no
                        reason.</p>
                    <p>11.3. Bandwidth limit for shared hosting is set in the hosting plan chosen by the customer.
                        Shared hosting plans with unmetered bandwidth means Priority Prospect does not restrict the
                        bandwidth usage, but reserves a right to suspend or terminate the Service when abuse is
                        detected. Customers using shared hosting plans with unmetered bandwidth may use enough bandwidth
                        for their websites as it's required, but only in reasonable amounts.</p>
                    <p>
                        <a id="licenses" href="#licenses">12. Licenses</a>
                    </p>
                    <p>12.1. Priority Prospect grants to you a non-exclusive, non-transferable, worldwide, royalty free
                        license to use technology provided by Priority Prospect solely to access and use the Services.
                        This license terminates on the expiration or termination of this Agreement. Except for the
                        license rights set out above, this license does not grant any additional rights to you. All
                        right, title and interest in Priority Prospect's technology shall remain with Priority Prospect,
                        or its licensors. You are not permitted to circumvent any devices designed to protect Priority
                        Prospect, or its licensor's ownership interests in the technology provided to you. In addition,
                        you may not reverse engineer this technology.</p>
                    <p>12.2. You grant Priority Prospect, or to any third parties used by Priority Prospect to provide
                        the Services, a non-exclusive, non-transferable, worldwide, royalty free, license to use,
                        disseminate, transmit and cache content, technology and information provided by you and, if
                        applicable, your End Users, in conjunction with the Services. This license terminates on the
                        expiration or termination of this Agreement. All right, title and interest in your technology
                        shall remain with you, or your licensors.</p>
                    <p>
                        <a id="service-modifications" href="#service-modifications">13. Service Modifications</a>
                    </p>
                    <p>13.1. Priority Prospect reserves the right to add, modify, or remove any or all features from any
                        service Priority Prospect provides, at any time, with or without notice. This includes, but is
                        not limited to, disk space limits, bandwidth limits, domain limits, pricing, and third party
                        applications. These changes can be made for any or no reason and Priority Prospect does not
                        guarantee the availability of any feature, whether written or implied. If the removal of a
                        feature materially impacts your ability to use the Service, you may terminate this Agreement.
                        For the purposes of this paragraph only, the term "materially" means that a reasonable business
                        person would not have purchased the Services for the purposes used by you.</p>
                    <p>
                        <a id="uptime-guarantee" href="#uptime-guarantee">14. Uptime Guarantee</a>
                    </p>
                    <p>14.1. Priority Prospect does not offer an uptime guarantee at this time. If you feel that your
                        uptime in a given month has fallen short of your expectations, please contact our billing team
                        and our team will be happy to assist you.</p>
                    <p>
                        <a id="support-policy" href="#support-policy">15. Support Policy</a>
                    </p>
                    <p>15.1. Priority Prospect will provide technical support to you twenty-four (24) hours a day,
                        three-hundred-sixty-five (365) days a year. The only official method for technical support is
                        via Priority Prospect's help desk at https://support.priorityprospect.com. Telephone and live
                        chat support are not official methods of support and are governed by the terms and conditions
                        set forth in the following clause.</p>
                    <p>15.2. Limited support will be provided, at Priority Prospect's discretion and subject to
                        availability of staff, via telephone and/or live chat. Priority Prospect will always do its best
                        to provide fast, friendly, and helpful support over the telephone and live chat, but these
                        options are not official methods of support and no guarantee is made as to the availability,
                        accessibility, or technical expertise provided via these mediums.</p>
                    <p>15.3. Inquires via telephone or live chat support should be limited to general questions which do
                        not require access to any account or server to investigate or resolve and should not be used to
                        request the status of or provide updates to a help desk ticket. Any issue requiring
                        investigation, research, or access to an account/server should be sent via the help desk.
                        Priority Prospect reserves the right to direct any issue to the help desk at its discretion and
                        to refuse to provide support for such issues via telephone or live chat.</p>
                    <p>
                        <a id="advanced-support-policy" href="#advanced-support-policy">16. Advanced Support Policy</a>
                    </p>
                    <p>16.1. Support to Customer is limited to Priority Prospect's area of expertise and is available
                        only for issues related to the physical functioning of the Services. Priority Prospect does not
                        provide support for any third party software including, but not limited to, software offered by
                        but not developed by Priority Prospect. Priority Prospect reserves the right to refuse
                        assistance with and/or assess an "Advanced Support Fee" of $100.00 USD per hour (1 hour minimum)
                        for any issue that, at Priority Prospect's sole discretion, is: (a) outside the scope of
                        standard support; or (b) caused by customer error or omission. Priority Prospect will always ask
                        for your permission before providing advanced support that may be subject to a fee. By providing
                        your permission, you agree to pay Advanced Support Fees as billed.</p>
                    <p>
                        <a id="backup-services" href="#backup-services">17. Backup Services</a>
                    </p>
                    <p>17.1. Your use of Priority Prospect's Services is at your sole risk. This service is provided to
                        you as a courtesy. Priority Prospect is not responsible for files and/or data residing on your
                        account. You agree to take full responsibility for files and data transferred and to maintain
                        all appropriate backup of files and data stored on Priority Prospect's servers. Any and all
                        backup services provided by Priority Prospect, whether paid or not, are offered with no warranty
                        or guarantee of their date, accuracy, and integrity.</p>
                    <p>17.2. Backups are performed on servers that are used for shared hosting only.</p>
                    <p>17.3. If you ask Priority Prospect to restore a backup of your data that is located on a backup
                        server maintained by Priority Prospect, you will receive one courtesy backup restore per
                        calendar month. For any further restores within the same calendar month, you agree to pay a
                        non-refundable "restoration fee" of $10.00 per backup restored to complete the request. By
                        requesting that Priority Prospect restore a backup for you, whether paid or unpaid, you also
                        confirm a backup restoration will overwrite any and all current data on your account. Priority
                        Prospect cannot be held liable if a backup restoration does not complete successfully. Priority
                        Prospect will never charge a backup fee to restore a backup to address an error or omission
                        caused by Priority Prospect.</p>
                    <p>17.4 Priority Prospect will keep backups of all active accounts for at least Fourteen (14)
                        calendar days.</p>
                    <p>17.4.1 Priority Prospect can delete some or all of your backups at any time after you stop paying
                        for Priority Prospect's Services.</p>
                    <p>
                        <a id="acceptable-use-policy" href="#acceptable-use-policy">18. Acceptable Usage Policy</a>
                    </p>
                    <p>18.1. You shall use Priority Prospect's services only for lawful purposes. Transmission, storage,
                        or presentation of any information, data, or material in violation of the laws of Estonia is
                        prohibited. This includes, but is not limited to: copyrighted material in which you are not the
                        copyright holder, material that is threatening or obscene, or material protected by trade
                        secrets or other statutes. You agree to indemnify and hold harmless Priority Prospect from any
                        claims resulting from the use of the service which damages you or any other party.</p>
                    <p>18.2. Priority Prospect reserves the right to terminate Services for any customer or End User
                        activity that exposes it to legal liability or endangers its ability to provide services to
                        other customers. The contents, links, or actions on any Services listed below are a violation of
                        this Agreement. This is not an exhaustive list, but is designed to assist you in evaluating
                        whether Priority Prospect is the appropriate service provider for you:</p>
                    <p>Child Pornography - Hosting, Distributing, or Linking to Pornography Involving a Person Under
                        Legal Age <br/>Copyright Infringement - Hosting, Distributing, or Linking to Copyright Infringed
                        Materials <br/>CPU/Memory/Resource Abuse - Consuming Excessive Amount of Server Resources
                        Causing Server Performance Issues <br/>DoS Source - Source of Denial of Service Attack <br/>DoS
                        Target - Target of Denial of Service Attack <br/>File Scripts - File Dump/Mirror Scripts
                        (similar to rapidshare) <br/>Forgery - Faking an IP Address, Hostname, E-Mail Address, or
                        Header <br/>Fraud Site - Hosting or Linking to a Website Intended to Deceive the Public
                        including, but not limited to sites listed at aa419.org &amp; escrow-fraud.com <br/>HYIP -
                        Hosting or Linking to a High Yield Investment Program Website <br/>Identity Theft - Hosting,
                        Distributing, or Linking to Stolen Account Identification Information <br/>Infection - Hosting,
                        Distributing, or Linking to Exploits, Trojans, Viruses, or Worms <br/>Investment Sites - FOREX,
                        E-Gold Exchange, Second Life/Linden Exchange, Ponzi, MLM/Pyramid Scheme, High-Yield Interest
                        Programs (HYIP) or Related Sites <br/>IRC - Internet Relay Chat Server, including IRC
                        Scripts/Bots <br/>Mass Storage - Storing Mass Amounts of Backups, Archives, Videos, etc. <br/>PayDay
                        Loan Sites - including any site related to PayDay loans, PayDay loan affiliate programs,
                        etc <br/>Pharmacy Sites - Sites that engage in the illegal distribution of prescription
                        medications, including, but not limited to, promotion, marketing, or sale of prescription
                        medications without a valid prescription. <br/>Phishing - Identity Theft by Email Under False
                        Pretense <br/>Proxy Site - Hosting of or linking to an Anonymous Proxy Server <br/>Bulk Email -
                        No more than 500 emails of similar content (Opt In) <br/>Spam Email - Unsolicited Commercial
                        Email (UCE) or Unsolicited Bulk Email (UBE) <br/>Spam List - Hosting, Distributing, or Linking
                        to Email Address Lists for Spam <br/>Spam Site - A Site Advertised by Spam Email or Spam
                        Web <br/>Spam Ware - Hosting, Distributing, or Linking to Software Designed for Spamming <br/>Spam
                        Web - Unsolicited, Bulk, or Forged Site Advertisement in Web Logs, Forums, Guestbooks, or Social
                        Media <br/>Spamhaus - Spam Causing Blacklisting of an IP at www.spamhaus.org for malicious
                        activity <br/>Terrorist Site - Hosting or Linking to a Site Advocating Terrorism <br/>Toolz -
                        Hosting, Distributing, or Linking to Tools or Instructional Material on Hacking/Cracking or
                        Other Illegal Activity <br/>Trademark - Hosting, Distributing, or Linking to Trademark Infringed
                        Materials <br/>Warez - Hosting, Distributing, or Linking to Crackz, Hackz, KeyGenz, Serialz, or
                        Pirated Software <br/>Any other material Priority Prospect judges to be threatening or obscene.
                    </p>
                    <p>18.3. In addition, hosting accounts may not be resold. You may not resell individual services
                        within any single account such as web space, emails accounts, add on domains, or mailing lists.
                        All domains, add-on domains, and sub domains must be for sites controlled by or promoting
                        published content or businesses that are solely controlled by the owner of the account as
                        described above.</p>
                    <p>18.4. Priority Prospect responds to valid notices of alleged copyright infringement. Copyright
                        complaints are handled pursuant to the Digital Millennium Copyright Act ("DMCA"). Complaints
                        that do not comply with the DMCA will not be processed. You are required to comply with the DMCA
                        in using Priority Prospect's Services, and presenting copyright complaints. For more information
                        on how to submit a DMCA complaint and to view information on Priority Prospect's designated
                        part, <a href="/legal/dmca-policy/" rel="nofollow">please see our DMCA Policy</a>. </p>
                    <p>18.5. You are prohibited to utilize the Services to send unsolicited bulk and/or commercial
                        messages over the Internet (known as "spam" or "spamming"). It is not only harmful because of
                        its negative impact on consumer attitudes toward the Priority Prospect, but also because it can
                        overload the Priority Prospect's network and disrupt service to its customers subscribers.
                        Maintaining an open SMTP relay is prohibited. Any direct action, configuration, or setting that
                        causes excessive outbound email traffic is subject to review and possible action. When a
                        complaint is received, the Priority Prospect has the absolute and sole discretion to determine
                        from all of the evidence whether the email recipients were from an "opt-in" email list, or
                        whether the outbound email traffic generated from an account is suitable for a shared hosting
                        environment.</p>
                    <p>
                        <a id="restrictions-on-storage-space-usage" href="#restrictions-on-storage-space-usage">19.
                            Restrictions on Storage Space Usage</a>
                    </p>
                    <p>19.1. All accounts are subject to the following restrictions on storage space usage: a) accounts
                        must have valid, working websites, and not violate any previously subscribed terms; b) accounts
                        are not for use of mass storage of backups, files, audio, video, archive files or others, as
                        determined by Priority Prospect, at its sole discretion; and c) accounts are not for use of mass
                        distribution of files, such as torrents or mirrors. Any account found violating these terms may
                        be suspended or terminated without warning.</p>
                    <p>
                        <a id="multi-location-hosting" href="#multi-location-hosting">20. Multi-Location Hosting</a>
                    </p>
                    <p>20.1. Priority Prospect does not guarantee the availability of any and all host locations, and
                        reserves the right to add, move, modify, or remove any location at any time without
                        notification. Priority Prospect also reserves the right to deny the transfer of any account to a
                        new location for any or no reason.</p>
                    <p>
                        <a id="warranties" href="#warranties">21. Warranties</a>
                    </p>
                    <p>21.1. Your Warranties to Priority Prospect</p>
                    <p>21.1.1. You represent and warrant to Priority Prospect that: (i) you have the experience and
                        knowledge necessary to use the Services; (ii) you will provide Priority Prospect with material
                        that may be implemented by it to provide the Services without extra effort on Priority
                        Prospect's part; and (iii) you have sufficient knowledge about administering, designing, and
                        operating the functions facilitated by the Service to take advantage of it.</p>
                    <p>21.1.2. You expressly warrant that you own the entire right, title and interest to, or have an
                        appropriate license to use, all material provided to Priority Prospect, or which may be accessed
                        or transmitted using the Services. You also warrant that to the extent you do business with
                        other parties using the Services, that they have the same ownership interests in the materials
                        provided to you, or accessed via you, that are set out in this paragraph.</p>
                    <p>21.2. Priority Prospect's Warranties</p>
                    <p>21.2.1. YOU EXPRESSLY AGREE THAT USE OF PRIORITY PROSPECT'S SERVICES IS AT YOUR OWN RISK. THE
                        SERVICES ARE PROVIDED AS-IS AND AS-AVAILABLE. OTHER THAN AS EXPRESSLY SET OUT IN THIS AGREEMENT,
                        PRIORITY PROSPECT HAS NOT, AND DOES NOT, MAKE ANY WARRANTIES WHETHER EXPRESS OR IMPLIED. THIS
                        DISCLAIMER INCLUDES, BUT IS NOT LIMITED TO, THE WARRANTIES OR NON-INFRINGEMENT, FITNESS FOR A
                        PARTICULAR PURPOSE, WARRANTIES OR MERCHANTABILITY, AND/OR TITLE. NEITHER PRIORITY PROSPECT, ITS
                        PARENT, ITS EMPLOYEES, AGENTS, RESELLERS, THIRD PARTY INFORMATION PROVIDERS, MERCHANTS LICENSERS
                        OR THE LIKE, WARRANT THAT PRIORITY PROSPECT'S SERVICES WILL NOT BE INTERRUPTED OR BE ERROR-FREE;
                        NOR DO THEY MAKE ANY WARRANTY AS TO THE RESULTS THAT MIGHT BE OBTAINED FROM THE USE OF THE
                        SERVICES OR AS TO THE ACCURACY, OR RELIABILITY, OF ANY INFORMATION SERVICE OR MERCHANDISE
                        CONTAINED IN OR PROVIDED THROUGH PRIORITY PROSPECT'S NETWORK, UNLESS OTHERWISE EXPRESSLY STATED
                        IN THIS AGREEMENT. PRIORITY PROSPECT SPECIFICALLY DISCLAIMS ANY AND ALL WARRANTIES REGARDING
                        SERVICES PROVIDED BY THIRD PARTIES, REGARDLESS OF WHETHER THOSE SERVICES APPEAR TO BE PROVIDED
                        BY PRIORITY PROSPECT. NO WARRANTIES MADE BY THESE THIRD PARTIES TO PRIORITY PROSPECT SHALL BE
                        PASSED THROUGH TO YOU, NOR SHALL YOU CLAIM TO BE A THIRD PARTY BENEFICIARY OF SUCH
                        WARRANTIES.</p>
                    <p>21.2.2. THE WARRANTY DISCLAIMERS CONTAINED IN THIS AGREEMENT EXTEND TO ANY ORAL OR WRITTEN
                        INFORMATION YOU MAY HAVE RECEIVED FROM PRIORITY PROSPECT, ITS EMPLOYEES, THIRD-PARTY VENDORS,
                        AGENTS OR AFFILIATES. YOU MAY NOT RELY ON SUCH INFORMATION.</p>
                    <p>21.2.3. SOME STATES DO NOT ALLOW PRIORITY PROSPECT TO EXCLUDE CERTAIN WARRANTIES. IF THIS APPLIES
                        TO YOU, YOUR WARRANTY IS LIMITED TO NINETY (90) DAYS FROM THE EFFECTIVE DATE.</p>
                    <p>21.3. The parties expressly disclaim the applicability of the United Nations Convention on the
                        International Sale of Goods.</p>
                    <p>
                        <a id="limitation-of-liability" href="#limitation-of-liability">22. Limitation of Liability</a>
                    </p>
                    <p>22.1. YOU ALSO ACKNOWLEDGE AND ACCEPT THAT ANY DAMAGES WILL BE LIMITED TO NO MORE THAN THE FEES
                        PAID BY YOU FOR ONE (1) MONTH OF SERVICE.</p>
                    <p>22.2. UNDER NO CIRCUMSTANCES, INCLUDING NEGLIGENCE, SHALL PRIORITY PROSPECT, ITS OFFICERS, AGENTS
                        OR THIRD PARTIES PROVIDING SERVICES THROUGH PRIORITY PROSPECT, BE LIABLE FOR ANY DIRECT,
                        INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING BUT NOT
                        LIMITED TO, DAMAGES FOR LOSS OF PROFITS, COST SAVINGS, REVENUE, BUSINESS, DATA OR USE, OR ANY
                        OTHER PECUNIARY LOSS BY YOU, ANY OF YOUR END USERS OR ANY THIRD PARTY; OR THAT RESULTS FROM
                        MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES, ERRORS, DEFECTS, DELAYS IN OPERATION, OR
                        TRANSMISSION OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT LIMITED TO ACTS OF GOD, COMMUNICATION
                        FAILURE, THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO PRIORITY PROSPECT RECORDS, PROGRAMS OR
                        SERVICES. YOU AGREE THAT THIS PARAGRAPH APPLIES EVEN IF PRIORITY PROSPECT HAS BEEN ADVISED OF
                        THE POSSIBILITY OF SUCH DAMAGES. YOU HEREBY ACKNOWLEDGE THAT THIS PARAGRAPH SHALL APPLY TO ALL
                        CONTENTS ON ALL SERVERS AND ALL SERVICES. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR
                        EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES; YOU AGREE THAT IN THOSE
                        JURISDICTIONS, PRIORITY PROSPECT'S LIABILITY WILL BE LIMITED TO THE EXTENT PERMITTED BY LAW.</p>
                    <p>
                        <a id="indemnification" href="#indemnification">23. Indemnification</a>
                    </p>
                    <p>23.1. You agree to indemnify, defend and hold harmless Priority Prospect, and its parent,
                        subsidiary and affiliated companies, third party service providers and each of their respective
                        officers, directors, employees, shareholders and agents (each an "indemnified party" and
                        collectively, "indemnified parties") from and against any and all claims, damages, losses.
                        liabilities, suits, actions, demands, proceedings (whether legal or administrative), and
                        expenses (including, but not limited to, reasonable attorneys' fees) threatened, asserted, or
                        filed by a third party against any of the indemnified parties arising out of, or relating to:
                        (i) your use of the Services; (ii) any violation by you of any of Priority Prospect's policies;
                        (iii) any breach of any of your representations, warranties or covenants contained in this
                        Agreement; or (iv) any acts or omissions by you. The terms of this section shall survive any
                        termination of this Agreement. For the purpose of this paragraph only, the terms used to
                        designate you include you, your customers, visitors to your website, and users of your products
                        or services the use of which is facilities by Priority Prospect.</p>
                    <p>
                        <a id="governing-law-and-disputes" href="#governing-law-and-disputes">24. Governing Law and
                            Disputes</a>
                    </p>
                    <p>24.1. This agreement shall be governed by the laws of the country of Estonia, exclusive of its
                        choice of law principles, and the laws of the country of Estonia, as applicable. Exclusive venue
                        for all disputes arising out of or relating to this Agreement shall be the state and federal
                        courts in Rakvere, Estonia, and each party agrees not to dispute such personal jurisdiction and
                        waives all objections thereto.</p>
                    <p>
                        <a id="partial-invalidity" href="#partial-invalidity">25. Partial Invalidity</a>
                    </p>
                    <p>25.1. If any provision of this Agreement is held to be invalid by a court of competent
                        jurisdiction, then the remaining provisions shall nevertheless remain in full force and effect.
                        Priority Prospect and Customer agree to renegotiate any term held invalid and to be bound by
                        mutually agreed substitute provision.</p>
                    <p>
                        <a id="change-to-the-terms-of-service" href="#change-to-the-terms-of-service">26. Changes to the
                            Terms of Service</a>
                    </p>
                    <p>26.1. Priority Prospect reserves the right to modify this Agreement, in whole or in part, from
                        time-to-time. Priority Prospect will provide you with notices of such a change by sending a
                        notice to your email address on file. Unless Priority Prospect is required to make a change in
                        an emergency, any change will be effective seven (7) days after it is posted. If such a change
                        materially diminishes your ability to use the Services, you may terminate this Agreement. You
                        are encouraged to review the content of this Agreement on a regular basis.</p>
                    <p>
                        <a id="assignment" href="#assignment">27. Assignment</a>
                    </p>
                    <p>27.1. This Agreement may be assigned by Priority Prospect. It may not be assigned by you. This
                        Agreement shall bind and inure to the benefit of the corporate successors and permitted assigns
                        of the parties.</p>
                    <p>
                        <a id="force-majeure" href="#force-majeure">28. Force Majeure</a>
                    </p>
                    <p>28.1. Except for the obligation to pay monies due and owing, neither party shall be liable for
                        any delay or failure in performance due to events outside the defaulting party's reasonable
                        control, including, without limitation, acts of God, earthquake, labor disputes, shortages of
                        supplies, riots, war, fire, epidemics, failure of telecommunication carriers, or delays of
                        common carriers or other circumstances beyond its reasonable control. The obligations and rights
                        of the excused party shall be extended on a day-to-day basis for the time period equal to the
                        period of the excusable delay. The party affected by such an occurrence shall notify the other
                        party as soon as possible but in no event less than ten (10) days from the beginning of the
                        event.</p>
                    <p>
                        <a id="no-waiver" href="#no-waiver">29. No Waiver</a>
                    </p>
                    <p>29.1. No waiver of rights under this Agreement or any Priority Prospect policy, or agreement
                        between Customer and Priority Prospect shall constitute a subsequent waiver of this or any other
                        right under this Agreement.</p>
                    <p>
                        <a id="no-agency" href="#no-agency">30. No Agency</a>
                    </p>
                    <p>30.1. This Agreement does not create any agency, partnership, joint venture, or franchise
                        relationship. Neither party has the right or authority to, and shall not, assume or create any
                        obligation of any nature whatsoever on behalf of the other party or bind the other party in any
                        respect whatsoever.</p>
                    <p>
                        <a id="hipaa-disclaimer" href="#hipaa-disclaimer">31. HIPAA Disclaimer</a>
                    </p>
                    <p>31.1. We are not "HIPAA compliant." Users are solely responsible for any applicable compliance
                        with federal or state laws governing the privacy and security of personal data, including
                        medical or other sensitive data. Users acknowledge that the Services may not be appropriate for
                        the storage or control of access to sensitive data, such as information about children or
                        medical or health information. Priority Prospect does not control or monitor the information or
                        data you store on, or transmit through, our Services. We specifically disclaim any
                        representation or warranty that the Services, as offered, comply with the federal Health
                        Insurance Portability and Accountability Act ("HIPAA"). Customers requiring secure storage of
                        "protected health information" under HIPAA are expressly prohibited from using this Service for
                        such purposes. Storing and permitting access to "protected health information," as defined under
                        HIPAA is a material violation of this User Agreement, and grounds for immediate account
                        termination. We do not sign "Business Associate Agreements" and you agree that Priority Prospect
                        is not a Business Associate or subcontractor or agent of yours pursuant to HIPAA. If you have
                        questions about the security of your data, you should contact customer support.</p>
                </div>
                <Button block type='primary' onClick={() => this.nextStep()}>I agree with Priority Prospect's Terms of Service</Button>
                <Button block type='default' style={{ width: '100%', marginTop: '15px'}} onClick={() => this.previousStep()}>Back</Button>
            </div>
        } else if(this.state.step === 4) {

            content = <div id='privacy-policy-content'>
                <h1 className='text-center'>Privacy Policy</h1>
                <div id='privacy-policy' className='privacy-policy terms-container'>
                    <p>Priority Prospect OÜ (“Priority Prospect”) offers web hosting products and multiple different services to it’s international customers. We respect and protect our customer’s private information. This policy begins applying to visitors and customers as soon as they visit Priority Prospect’s website, or websites that Priority Prospect owns, for the first time.</p>
                    <p>
                        <strong>Why this policy exists?</strong>
                    </p>
                    <p>This data protection policy ensures Priority Prospect</p>
                    <ul>
                        <li>Complies with data protection law and follows good practice</li>
                        <li>Protects the rights of staff, customers and partners</li>
                        <li>Is transparent about how it stores and processes individuals personal data</li>
                        <li>Protects itself from the risk of a data breach</li>
                    </ul>
                    <p>
                        <strong>The General Data Protection Regulation</strong>
                    </p>
                    <p>The EU General Data Protection Regulation (GDPR) replaces the Data Protection Directive 95/46/EC and was designed to harmonize data privacy laws across Europe, to protect and empower all EU citizens’ data privacy and to reshape the way organizations across the region approach data privacy. The GDPR aims primarily to give control back to citizens and residents over their personal data and to simplify the regulatory environment for international business by unifying the regulation within the EU.</p>
                    <p>
                        <strong>What is “Personal Data”?</strong>
                    </p>
                    <p>“Personal data” is defined in both the Directive and the GDPR as any information relating to a person who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that person. So in many cases online identifiers including IP address, cookies and so forth will now be regarded as personal data if they can be (or are capable of being) without undue effort linked back to the data subject.</p>
                    <p>
                        <strong>1. Information collection and use</strong>
                    </p>
                    <p>
                        <strong>1.1 Website visitors</strong>
                    </p>
                    <p>Priority Prospect collects non-personally-identifying information that web servers and browsers provide. It includes: browser type, language preference, referring website, date and time of each request made by the visitor. That information is collected by Google Analytics and/or GetClicky and/or Freshworks. The purpose of collecting non-personally-identifying data is to understand how visitors browse Priority Prospect’s websites. It is not used for any additional purpose, such as to profile those who access our website.</p>
                    <p>
                        <strong>1.2 Gathering of personally-identifying information</strong>
                    </p>
                    <p>Certain visitors on websites owned by Priority Prospect may interact with Priority Prospect in ways that require Priority Prospect to gather personally identifying information. The amount and type of the information gathered by Priority Prospect depends on the nature of the interaction. Those who engage in transactions with Priority Prospect, usually by purchasing different services, are asked to provide additional information, including as necessary the personal and financial information required to process those transactions. In each case, Priority Prospect collects such information only insofar as is necessary or appropriate to fulfill the purpose of the visitor’s interaction with Priority Prospect. Visitors can always refuse to supply personally-identifying information with the caveat that it may prevent them from engaging in certain website-related activities, including from becoming a customer of Priority Prospect.</p>
                    <p>In some cases, Priority Prospect will ask website visitors wishing to become customers of Priority Prospect to provide additional information beyond what was requested in the signup form. This information is requested to verify customer’s identify and reduce the likelihood of fraud. Customers not wishing to provide such information as requested may not be permitted to become customers of Priority Prospect. We do not collect additional information, such as your age, gender, interests or bank details.</p>
                    <p>For certain services that Priority Prospect provides, Priority Prospect may collect data feed which includes the action that Priority Prospect’s client has performed, IP address from which that action was performed and a timestamp. This information is used to detect anomalies in behavior or malicious behavior so Priority Prospect can use that information to re-construct the situation and to help to restore Priority Prospect client’s data if needed.</p>
                    <p>
                        <strong>2. When you contact us</strong>
                    </p>
                    <p>While you can use websites owned by Priority Prospect without giving out your personal information, once you contact Priority Prospect via the any of&nbsp; website, Priority Prospect collects information about you.</p>
                    <p>The information you fill in (personal information such as your name, email address, organization) will be processed and stored so that it is possible for us to contact and respond to your request, and/or allow you access to our services.</p>
                    <p>
                        <strong>2.1. Recruitment</strong>
                    </p>
                    <p>If you apply for a job with Priority Prospect, we will collect, process and store the information you have sent us for recruitment-related purposes, such as contacting you. Additionally, Priority Prospect may keep your data for a period of time for the purpose of considering you for a different opportunity.</p>
                    <p>
                        <strong>3. Social media plug-ins</strong>
                    </p>
                    <p>Websites owned by Priority Prospect may feature multiple social plug-ins for easy sharing and following social media pages owned by Priority Prospect: Facebook, operated by Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA; LinkedIn, operated by LinkedIn, 2029 Sterling Court Mountain View, CA 94043 United States; Twitter, operated by Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103 United States; Reddit, operated by Reddit, Inc., 520 3rd Street San Francisco, CA 94107 United States; Google, operated by Google LLC, 1600 Amphitheatre Parkway Mountain View, CA 94043 United States.</p>
                    <p>Being on our website does not automatically result in sharing data on these social media networks. These plugins remain inactive (idle) until clicked upon. Once clicked upon, you will be taken to the said social media networks with their own specific privacy policies you are recommended to consult.</p>
                    <p>
                        <strong>4. Protection of certain personally-identifying information</strong>
                    </p>
                    <p>Priority Prospect discloses potentially personally-identifying and personally information only to those of it’s employees, contractors and affiliate organizations that (i) need to know that information in order to process it on Priority Prospect’s behalf or provide services available to Priority Prospect’s customers, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using Priority Prospect’s products and services, you consent to the transfer of such information to them. Priority Prospect will not rent, sell or share potentially personally-identifying information only in response to a subpoena, court order or other governmental request.</p>
                    <p>If you are a customer of Priority Prospect and have supplied your email address, Priority Prospect may occasionally send you an email to notify you about new products or services, solicit your feedback, inform you of an outage or interruption in service, or just keep you up to date with what is going on with Priority Prospect and their products and services. Priority Prospect takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information. This is done through appropriate technical measures.</p>
                    <p>
                        <strong>5. Access to information</strong>
                    </p>
                    <p>You have the right to request access to the information Priority Prospect have on you. You can do this by contacting us at support@priorityprospect.com. Priority Prospect will make sure to provide you with a copy of the data we process about you. In order to comply with your request, Priority Prospect may ask you to verify your identity. Priority Prospect will fulfill your request by sending your copy electronically, unless the request expressly specifies a different method. For any subsequent access request, Priority Prospect may charge you with an administrative fee.</p>
                    <p>
                        <strong>6. Information correction and deletion</strong>
                    </p>
                    <p>If you believe that the information Priority Prospect have about you is incorrect, you are welcome to contact Priority Prospect so Priority Prospect can update it and keep your data accurate. Any data that is no longer needed for the purposes specified in Information collection and use will be deleted.</p>
                    <p>If at any point you wish for Priority Prospect to delete information about you, you can simply contact Priority Prospect.</p>
                    <p>
                        <strong>7. Policy changes</strong>
                    </p>
                    <p>Although most changes are likely to be minor, Priority Prospect may change it’s privacy policy from time to time, with or without notice, and in Priority Prospect’s sole discretion.</p>
                    <p>Priority Prospect will post changes to it’s privacy policy directly on this page and encourages visitors to regularly check this page for changes to this privacy policy.</p>
                    <p>Your continued use of Priority Prospect’s products and services after any change in this privacy policy will constitute your acceptance of such change.</p>
                    <p>This privacy policy was changed on <strong>15.01 2020</strong>. </p>
                    <p>
                        <strong>8. To sum up</strong>
                    </p>
                    <p>In accordance with applicable law, Priority Prospect only collects a limited amount of information about you that is necessary for improving our service. Priority Prospect does not use profiling, Priority Prospect does not sell or in any other way spread your data to third parties, and Priority Prospect does not use your data for purposes other than what we specified. Priority Prospect also makes sure that your data is stored securely. Priority Prospect deletes all information deemed no longer necessary. Priority Prospect constantly review our Privacy Policy in order to make it better and protect you more.</p>
                    <p>
                        <strong>9. You can contact Priority Prospect at any time to:</strong>
                    </p>
                    <ul>
                        <li>Request access to information that Priority Prospect has about you</li>
                        <li>Correct any information that Priority Prospect has about you</li>
                        <li>Delete information that Priority Prospect has about you</li>
                    </ul>
                    <p>
                        <strong>Our information:</strong>
                    </p>
                    <p>Priority Prospect OÜ <br />Registration no: 14059959 <br />VAT ID: EE101884183 </p>
                    <p>Vandu tee 6-2, Hulja, <br />Lääne Virumaa, Estonia <br />45203 </p>
                    <p>support@priorityprospect.com <br />+372 6600425 </p>
                    <p>
                        <strong>Glossary of terms</strong>
                    </p>
                    <ul>
                        <li>
                            <strong>Cookies</strong>
                            <br />Cookies are small files or other pieces of data which are downloaded or stored on your computer or other device that can be tied to information about your use of our website (including certain third party services and features offered as part of our website).
                        </li>
                        <li>
                            <strong>Device</strong>
                            <br />A device is what you use to reach our website, such as a smartphone, tablet or computer.
                        </li>
                        <li>
                            <strong>IP address</strong>
                            <br />Internet Protocol (IP) address is a number that is connected to your device. An IP address can be used to determine the location of your device typically within a 40km radius, thus not being an exact determination of your location. IP addresses are basically how the Internet functions.
                        </li>
                        <li>
                            <strong>Personal information</strong>
                            <br />Personal information is information that can identify you. This can be your name, email, contact information or other type of information that can be traced back to you.
                        </li>
                    </ul>
                </div>
                <Button block type='primary' onClick={() => this.nextStep()}>I agree with Priority Prospect's Privacy Policy</Button>
                <Button block type='default' style={{ width: '100%', marginTop: '15px'}} onClick={() => this.previousStep()}>Back</Button>
            </div>
        } else if(this.state.step === 5) {
            content = <Form layout='vertical'>
                <Form.Item>
                    <Checkbox onChange={(e) => this.setState({ agreeTerms: e.target.checked }) }>
                        I hereby acknowledge that I have read, understand and agree Priority Prospect OÜ's <a href='https://priorityprospect.com/legal/terms-of-service/' rel="noopener noreferrer"  target='_blank'><b>terms of service</b></a>
                        &nbsp;and&nbsp;<a href='https://priorityprospect.com/legal/privacy-policy/' rel="noopener noreferrer"  target='_blank'><b>privacy policy</b></a>.
                    </Checkbox>
                </Form.Item>
                <Button block type='primary' disabled={!this.state.agreeTerms} onClick={() => this.createAccount()}>Create Account</Button>
                <Button block type='default' style={{marginTop: '15px'}} onClick={() => {
                    this.setState({ agreeTerms: false });
                    this.previousStep();
                }}>Back</Button>
            </Form>;
        }

        return(
            <div className='auth-wrapper'>
                <Card className='form-container'>
                    <div className='logo-container'>
                        <img alt='Priority Prospect' src='/img/logo-top-dark.png' />
                    </div>
                    {content}
                </Card>
            </div>
        );
    }
}

export default connect(null, { updatePage, registerAccount, validateVAT })(Register);