import {
    CLOSE_GETTING_STARTED_WIZARD, MINIMIZE_GETTING_STARTED_WIZARD,
    OPEN_GETTING_STARTED_WIZARD, START_GETTING_STARTED_WIZARD,
    STOP_GETTING_STARTED_WIZARD, UPDATE_GETTING_STARTED_WIZARD_DOMAIN_GROUP, UPDATE_GETTING_STARTED_WIZARD_IP_GROUP,
    UPDATE_GETTING_STARTED_WIZARD_STEP
} from "./types";
import {httpDelete, httpPost} from "../libs/http";

export const startGettingStartedWizard = (guid) => (dispatch) => {
    httpPost('/account/companies/' + guid + '/getting-started/',  {'step': 2, 'start': true}, () => {
        dispatch({ type: START_GETTING_STARTED_WIZARD, payload: { step: 2, data: {ip_group: null, domain_group: null}} });
    }, () => {});
}

export const stopGettingStartedWizard = (guid) => (dispatch) => {
    httpDelete('/account/companies/' + guid + '/getting-started/', () => {
        dispatch({ type: STOP_GETTING_STARTED_WIZARD });
    }, () => {});

    dispatch({ type: STOP_GETTING_STARTED_WIZARD });
}

export const openGettingStartedWizard = () => (dispatch) => {
    dispatch({ type: OPEN_GETTING_STARTED_WIZARD });
}

export const closeGettingStartedWizard = () => (dispatch) => {
    dispatch({ type: CLOSE_GETTING_STARTED_WIZARD });
}

export const minimizeGettingStartedWizard = () => (dispatch) => {
    dispatch({ type: MINIMIZE_GETTING_STARTED_WIZARD });
}

export const updateGettingStartedWizardStep = (guid, step) => (dispatch) => {
    httpPost('/account/companies/' + guid + '/getting-started/',  {step}, () => {
        dispatch({ type: UPDATE_GETTING_STARTED_WIZARD_STEP, payload: step });
    }, () => {});
}

export const updateGettingStartedWizardIPGroup = (guid, ipGroup) => (dispatch) => {
    httpPost('/account/companies/' + guid + '/getting-started/data/',  {ip_group: ipGroup}, () => {
        dispatch({ type: UPDATE_GETTING_STARTED_WIZARD_IP_GROUP, payload: ipGroup });
    }, () => {});
}


export const updateGettingStartedWizardDomainGroup = (guid, domainGroup) => (dispatch) => {
    httpPost('/account/companies/' + guid + '/getting-started/data/',  {domain_group: domainGroup}, () => {
        dispatch({ type: UPDATE_GETTING_STARTED_WIZARD_DOMAIN_GROUP, payload: domainGroup });
    }, () => {});
}
