import React from "react";
import {connect} from "react-redux";
import {getDomainTrafficAnalytics} from "../../actions/domains";
import {convertBytes, displayErrors, normalizeEnum} from "../../libs/utils";
import {Loading} from "../../libs/loading";
import {Button, Card, Col, Row, Statistic, Table, Tooltip, DatePicker, Divider, Space} from "antd";
import WorldMap from "react-svg-worldmap";
import countryList from "react-select-country-list";
import {Area, AreaChart, XAxis, YAxis, Tooltip as ReTooltip} from "recharts";
import moment from "moment/moment";
import DomainAnalyticsDetailModal from "./DomainAnalyticsDetailModal";
import {UnorderedListOutlined} from "@ant-design/icons";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";

class DomainAnalytics extends React.Component {
    state = {
        loadingAnalytics: false,
        data: null,
        momentDates: [moment(), moment()],
        showDetailModal: false,
        detailField: null
    };

    countries = countryList();

    componentDidMount() {
        this.loadDomainTrafficAnalytics();
    }

    loadDomainTrafficAnalytics() {
        this.setState({ loadingAnalytics: true });

        this.props.getDomainTrafficAnalytics(this.props.guid, this.state.momentDates[0].format('YYYY-MM-DD'),  this.state.momentDates[1].format('YYYY-MM-DD'), (res) => {
            this.setState({ loadingAnalytics: false, data: res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loadingAnalytics: false });
            }
        });
    }

    updateDateRange(momentDates, dateStrings) {
        this.setState({ momentDates }, () => this.loadDomainTrafficAnalytics(dateStrings[0], dateStrings[1]));
    }

    render() {
        if(this.state.loadingAnalytics || this.state.data === null) {
            return <div className='text-center'><Loading /></div>;
        }

        const mapData = this.state.data.country_visits.map(country => ({
            country: country.country,
            value: country.visits,
        }))

        const countryTableColumns = [
            { title: 'Country', dataIndex: 'country_name', key: 'country_name' },
            { title: 'Visits', align: 'center', width: '20%', dataIndex: 'visits', key: 'visits' },
        ]

        const urlsTableColumns = [
            { title: 'Path', dataIndex: 'url', key: 'url', render: (item) => {
                if(item.length > 50) {
                    return <Tooltip title={item}>{item.substring(0, 50)}...</Tooltip>;
                }

                return item;
            }},
            { title: 'Visits', align: 'center', width: '20%', dataIndex: 'visits', key: 'visits' },
        ]

        const refererTableColumns = [
            { title: 'Referer', dataIndex: 'referer', key: 'referer', render: (item) => {
                if(item === 'DIRECT') {
                    return 'Direct';
                } else {
                    if(item.length > 50) {
                        return <Tooltip title={item}>{item.substring(0, 50)}...</Tooltip>;
                    }

                    return item;
                }
            }},
            { title: 'Visits', align: 'center', width: '20%', dataIndex: 'visits', key: 'visits' },
        ]

        const osTableColumns = [
            { title: 'OS', dataIndex: 'os', key: 'os' },
            { title: 'Visits', align: 'center', width: '20%', dataIndex: 'visits', key: 'visits' },
        ]

        const deviceTableColumns = [
            { title: 'Device', dataIndex: 'device_type', key: 'device_type', render: (item) => normalizeEnum(item) },
            { title: 'Visits', align: 'center', width: '20%', dataIndex: 'visits', key: 'visits' },
        ]

        const botTableColumns = [
            { title: 'Bot Type', dataIndex: 'bot_type', key: 'bot_type', render: (item) => normalizeEnum(item) },
            { title: 'Visits', align: 'center', width: '20%', dataIndex: 'visits', key: 'visits' },
        ]

        const browserTableColumns = [
            { title: 'Browser', dataIndex: 'browser', key: 'browser' },
            { title: 'Visits', align: 'center', width: '20%', dataIndex: 'visits', key: 'visits' },
        ]

        let countryVisitsData = this.state.data.country_visits.slice(0, 10);

        const { RangePicker } = DatePicker;

        const disabledDate = (current) => {
            return current && (current > moment().endOf('day') || current < moment().subtract(30, 'days'));
        };

        return <div>
            <Row justify='space-between' className='domain-analytics-view'>
                <Col />
                <Col>
                    <Space>
                        <Button type='text' icon={<UndoOutlined/>} onClick={() => this.loadDomainTrafficAnalytics()} />
                        <RangePicker
                            ranges={{
                                'Today': [moment(), moment()],
                                'Yesterday': [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
                                'This Week': [moment().startOf('week'), moment().endOf('week')],
                                'Last 7 Days': [moment().subtract(7, 'days'), moment()],
                                'Last 14 Days': [moment().subtract(14, 'days'), moment()],
                                'Last 30 Days': [moment().subtract(30, 'days'), moment()],
                            }}
                            format="YYYY-MM-DD"
                            defaultValue={this.state.momentDates}
                            value={this.state.momentDates}
                            disabledDate={disabledDate}
                            allowClear={false}
                            onChange={(dates, dateStrings) => this.updateDateRange(dates, dateStrings)} />
                    </Space>
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col span={8}>
                    <Statistic title="Visits" value={this.state.data.total_visits} />
                </Col>
                <Col span={8}>
                    <Statistic title="Unique Visits" value={this.state.data.unique_visits} />
                </Col>
                <Col span={8}>
                    <Statistic title="Bandwidth Usage" value={convertBytes(this.state.data.bandwidth_usage)} />
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Card bordered={false} size='small'>
                        <AreaChart
                            width={1100}
                            height={250}
                            data={this.state.data.domain_visits}
                            margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <XAxis dataKey="timestamp" />
                            <YAxis />
                            <ReTooltip contentStyle={{textTransform: 'capitalize'}} />
                            <Area type="monotone" dataKey="visits" stroke="#0f2463" fill="#0f2463" />
                        </AreaChart>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Card bordered={false} size='small' title='Countries'
                          extra={<Button size='small' icon={<UnorderedListOutlined />} onClick={() => this.setState({ showDetailModal: true, detailField: 'COUNTRIES' })}>Details</Button>}>
                        <Row>
                            <Col span={16}>
                                <WorldMap style={{width: '100%'}} color='#0f2463' data={mapData} />
                            </Col>
                            <Col span={8}>
                                <div style={{overflowX: 'auto'}}>
                                    <Table loading={this.state.loading}
                                           columns={countryTableColumns}
                                           dataSource={countryVisitsData}
                                           pagination={false} size='small'
                                           bordered={false} rowKey={(item) => item.country}/>
                                </div>
                            </Col>
                        </Row>

                    </Card>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Card bordered={false} size='small' title='Paths'
                          extra={<Button size='small' icon={<UnorderedListOutlined />}
                                         onClick={() => this.setState({
                                             showDetailModal: true,
                                             detailField: 'PATHS'
                                         })}>Details</Button>}>

                        <div style={{overflowX: 'auto'}}>
                            <Table loading={this.state.loading}
                                   columns={urlsTableColumns}
                                   dataSource={this.state.data.url_visits}
                                   pagination={false} size='small'
                                   bordered={false} rowKey={(item) => item.url}/>
                        </div>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bordered={false} size='small' title='Referers'
                          extra={<Button size='small' icon={<UnorderedListOutlined />}
                                         onClick={() => this.setState({
                                             showDetailModal: true,
                                             detailField: 'REFERERS'
                                         })}>Details</Button>}>
                        <div style={{overflowX: 'auto'}}>
                            <Table loading={this.state.loading}
                                   columns={refererTableColumns}
                                   dataSource={this.state.data.referer_visits}
                                   pagination={false} size='small'
                                   bordered={false} rowKey={(item) => item.referer}/>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Card bordered={false} size='small' title='Operating Systems'
                          extra={<Button size='small' icon={<UnorderedListOutlined />}
                                         onClick={() => this.setState({
                                             showDetailModal: true,
                                             detailField: 'OS'
                                         })}>Details</Button>}>
                        <div style={{overflowX: 'auto'}}>
                            <Table loading={this.state.loading}
                                   columns={osTableColumns}
                                   dataSource={this.state.data.os_visits}
                                   pagination={false} size='small'
                                   bordered={false} rowKey={(item) => item.os}/>
                        </div>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bordered={false} size='small' title='Browsers'
                          extra={<Button size='small' icon={<UnorderedListOutlined />}
                                         onClick={() => this.setState({
                                             showDetailModal: true,
                                             detailField: 'BROWSERS'
                                         })}>Details</Button>}>
                        <div style={{overflowX: 'auto'}}>
                            <Table loading={this.state.loading}
                                   columns={browserTableColumns}
                                   dataSource={this.state.data.browser_visits}
                                   pagination={false} size='small'
                                   bordered={false} rowKey={(item) => item.browser}/>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Card bordered={false} size='small' title='Devices'
                          extra={<Button size='small' icon={<UnorderedListOutlined />}
                                         onClick={() => this.setState({
                                             showDetailModal: true,
                                             detailField: 'DEVICES'
                                         })}>Details</Button>}>

                        <div style={{overflowX: 'auto'}}>
                            <Table loading={this.state.loading}
                                   columns={deviceTableColumns}
                                   dataSource={this.state.data.device_visits}
                                   pagination={false} size='small'
                                   bordered={false} rowKey={(item) => item.device_type}/>
                        </div>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bordered={false} size='small' title='Bot Types'
                          extra={<Button size='small' icon={<UnorderedListOutlined />}
                                         onClick={() => this.setState({
                                             showDetailModal: true,
                                             detailField: 'BOT_TYPES'
                                         })}>Details</Button>}>

                        <div style={{overflowX: 'auto'}}>
                            <Table loading={this.state.loading}
                                   columns={botTableColumns}
                                   dataSource={this.state.data.bot_visits}
                                   pagination={false} size='small'
                                   bordered={false} rowKey={(item) => item.bot_type}/>
                        </div>
                    </Card>
                </Col>
            </Row>

            <DomainAnalyticsDetailModal
                guid={this.props.guid}
                show={this.state.showDetailModal}
                field={this.state.detailField}
                dates={this.state.momentDates}
                close={() => this.setState({ showDetailModal: false, detailField: null })}
            />
        </div>
    }
}

export default connect(null, { getDomainTrafficAnalytics })(DomainAnalytics);