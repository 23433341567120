import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../actions/page";
import {Button, Card, Col, Descriptions, Divider, Row} from "antd";

class Support extends React.Component {
    componentDidMount() {
        this.props.updatePage('Support');
    }

    render() {
        return (
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Card title='Customer Support' size='small' extra={<a href='https://support.priorityprospect.com/' target='_blank' rel='noopener noreferrer'><Button type='primary' size='small'>Visit support panel</Button></a>}>
                                <p>Visit our support panel at: <a href='https://support.priorityprospect.com' target='_blank' rel='noopener noreferrer'>https://support.priorityprospect.com</a>.</p>
                                <p>Our support panel allows you to create and respond to support tickets. Please note that, for security purposes, the login information (username and password) for this panel are not shared with the support panel.</p>
                                <p>Additionally, you can contact us via email:</p>
                                <Divider style={{margin: '10px 0'}} />
                                <Row gutter={[16, 16]}>
                                    <Col span={5}>Technical support</Col>
                                    <Col span={19}><a href='mailto:support@priorityprospect.com'>support@priorityprospect.com</a></Col>
                                </Row>
                                <Divider style={{margin: '10px 0'}} />
                                <Row gutter={[16, 16]}>
                                    <Col span={5}>Sales department</Col>
                                    <Col span={19}><a href='mailto:sales@priorityprospect.com'>sales@priorityprospect.com</a></Col>
                                </Row>
                                <Divider style={{margin: '10px 0'}} />
                                <Row gutter={[16, 16]}>
                                    <Col span={5}>Billing department</Col>
                                    <Col span={19}><a href='mailto:billing@priorityprospect.com'>billing@priorityprospect.com</a></Col>
                                </Row>
                                <Divider style={{margin: '10px 0'}} />
                                <Row gutter={[16, 16]}>
                                    <Col span={5}>Abuse department</Col>
                                    <Col span={19}><a href='mailto:abuse@priorityprospect.com'>abuse@priorityprospect.com</a></Col>
                                </Row>
                                <Divider style={{margin: '10px 0'}} />
                                <p>Our technical support is available 24/7.</p>
                                <p>Sales, billing, and abuse departments are available from Monday to Friday from 10:00 to 18:00 EEST.</p>
                            </Card>
                            {typeof(this.props.user.user.company.account_manager) !== 'undefined' ? <>
                                <br />
                                <Card title='Account Manager' size='small'>
                                    <Descriptions bordered size='small' column={1}>
                                        <Descriptions.Item label='Name' style={{width: '50%'}}>
                                            {this.props.user.user.company.account_manager.first_name} {this.props.user.user.company.account_manager.last_name}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Email address '>
                                            <a href={'mailto:' + this.props.user.user.company.account_manager.email}>{this.props.user.user.company.account_manager.email}</a>
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Phone'>
                                            {this.props.user.user.company.account_manager.phone}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Availability'>
                                            Monday to Friday from 10:00 to 18:00 EEST
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Card>
                            </> : ''}
                        </Col>
                        <Col span={12}>
                            <Card title='Knowledge Base' size='small' extra={<a href='https://support.priorityprospect.com/knowledgebase' target='_blank' rel='noopener noreferrer'><Button type='primary' size='small'>Visit knowledge base</Button></a>}>
                                <p>Our knowledge base is available in our support panel: <a href='https://support.priorityprospect.com/knowledgebase' target='_blank' rel='noopener noreferrer'>https://support.priorityprospect.com/knowledgebase</a></p>
                                <p>Here are some of the most popular questions:</p>
                                <Divider style={{margin: '10px 0'}} />
                                <Row gutter={[16, 16]}>
                                    <Col>
                                        <a href='http://support.priorityprospect.com/knowledgebase/article/getting-started-with-priority-prospect' target='_blank' rel='noopener noreferrer'>Getting
                                            started with Priority Prospect</a>
                                    </Col>
                                </Row>
                                <Divider style={{margin: '10px 0'}} />
                                <Row gutter={[16, 16]}>
                                    <Col>
                                        <a href='http://support.priorityprospect.com/knowledgebase/article/which-nameservers-i-can-use' target='_blank' rel='noopener noreferrer'>Which
                                            nameservers I can use?</a>
                                    </Col>
                                </Row>
                                <Divider style={{margin: '10px 0'}} />
                                <Row gutter={[16, 16]}>
                                    <Col>
                                        <a href='http://support.priorityprospect.com/knowledgebase/article/how-to-install-ssl-to-my-domain' target='_blank' rel='noopener noreferrer'>How
                                            to install SSL to my domain?</a>
                                    </Col>
                                </Row>
                                <Divider style={{margin: '10px 0'}} />
                                <Row gutter={[16, 16]}>
                                    <Col>
                                        <a href='http://support.priorityprospect.com/knowledgebase/article/what-means-pending-check-in-my-domains-status' target='_blank' rel='noopener noreferrer'>What
                                            means "Pending Check" in my domain's status?</a>
                                    </Col>
                                </Row>
                                <Divider style={{margin: '10px 0'}} />
                                <Row gutter={[16, 16]}>
                                    <Col>
                                        <a href='http://support.priorityprospect.com/knowledgebase/article/my-website-is-not-opening-what-to-do' target='_blank' rel='noopener noreferrer'>My
                                            website is not opening, what to do?</a>
                                    </Col>
                                </Row>
                                <Divider style={{margin: '10px 0'}} />
                                <Row gutter={[16, 16]}>
                                    <Col>
                                        <a href='http://support.priorityprospect.com/knowledgebase/article/how-to-create-and-use-software-presets' target='_blank' rel='noopener noreferrer'>How
                                            to create and use software presets?</a>
                                    </Col>
                                </Row>
                                <Divider style={{margin: '10px 0'}} />
                                <Row gutter={[16, 16]}>
                                    <Col>
                                        <a href='http://support.priorityprospect.com/knowledgebase/article/my-wordpress-website-has-been-hacked-what-to-do' target='_blank' rel='noopener noreferrer'>My
                                            Wordpress website has been hacked, what to do?</a>
                                    </Col>
                                </Row>
                                <Divider style={{margin: '10px 0'}} />
                                <Row gutter={[16, 16]}>
                                    <Col>
                                        <a href='http://support.priorityprospect.com/knowledgebase/article/how-to-keep-my-wordpress-website-secure' target='_blank' rel='noopener noreferrer'>How
                                            to keep my Wordpress website secure?</a>
                                    </Col>
                                </Row>
                                <Divider style={{margin: '10px 0'}} />
                                <Row gutter={[16, 16]}>
                                    <Col>
                                        <a href='http://support.priorityprospect.com/knowledgebase/article/what-means-err_ssl_unrecognized_name_alert' target='_blank' rel='noopener noreferrer'>What
                                            means ERR_SSL_UNRECOGNIZED_NAME_ALERT?</a>
                                    </Col>
                                </Row>
                                <Divider style={{margin: '10px 0'}} />
                                <Row gutter={[16, 16]}>
                                    <Col>
                                        <a href='http://support.priorityprospect.com/knowledgebase/article/how-to-create-private-nameservers' target='_blank' rel='noopener noreferrer'>How to create private nameservers?</a>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
});

export default connect(mapStateToProps, { updatePage })(Support);