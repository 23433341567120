const initialState = {
    showWizard: false,
    currentStep: 1,
    isMinimized: false,
    wizardData: {
        ip_group: null,
        domain_group: null
    },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch(action.type) {
        case 'START_GETTING_STARTED_WIZARD':
            return {
                ...state,
                showWizard: true,
                currentStep: action.payload.step,
                wizardData: action.payload.data
            };
        case 'OPEN_GETTING_STARTED_WIZARD':
            return {
                ...state,
                showWizard: true,
                isMinimized: false
            };
        case 'CLOSE_GETTING_STARTED_WIZARD':
            return {
                ...state,
                showWizard: false
            };

        case 'UPDATE_GETTING_STARTED_WIZARD_STEP':
            return {
                ...state,
                currentStep: action.payload
            };
        case 'STOP_GETTING_STARTED_WIZARD':
            return {
                ...state,
                currentStep: 1,
                showWizard: false
            };
        case 'MINIMIZE_GETTING_STARTED_WIZARD':
            return {
                ...state,
                showWizard: false,
                isMinimized: true
            };
        case 'UPDATE_GETTING_STARTED_WIZARD_IP_GROUP':
            let ipGroupData = {...state.wizardData};
            ipGroupData['ip_group'] = action.payload

            return {
                ...state,
                wizardData: ipGroupData
            }
        case 'UPDATE_GETTING_STARTED_WIZARD_DOMAIN_GROUP':
            let domainGroupData = {...state.wizardData};
            domainGroupData['domain_group'] = action.payload

            return {
                ...state,
                wizardData: domainGroupData
            }
        default:
            return state;
    }
}